
function trackSocialCkicl(item_id, social_type, social_track_type) {
    jQ.post("ajaxapi.php", {
        track_social_click: "1",
        item_id: item_id,
        social_track_type: social_track_type,
        social_type: social_type,
        url: window.location.href
    });
}

function runTutorSearch(elem, event) {
    event.preventDefault();

    var online = ~~jQ('input[name="online"]:checked', elem).val();
    var is_category = ~~jQ('input[name="categoty_page"]', elem).val();
    var s = jQ('input[name="s"]', elem).val();
    var href = window.location.origin + window.location.pathname;

    if (online == 1) {
        if (is_category == 1)
            href = href.replace(/online-tutors\/index\.php|tutors\/index\.php/g, 'online-tutors');
        else
            href = href.replace(/online-tutors|tutors/g, 'online-tutors');
    }
    else {
        if (is_category == 1)
            href = href.replace(/online-tutors/g, 'tutors/index.php');
        else
            href = href.replace(/online-tutors/g, 'tutors');
    }

    if (s.length) {
        href += '?s=' + s;
    }

    window.location = href;

}

//############### Start Search ##############
function StartProcessLocationSearch(elem) {
    if (jQ(elem).prop("tagName") === 'FORM') {
        var form = elem;
    } else {
        var form = jQ(elem).parents("form").get(0);
    }
    var form_name = jQ(form).attr("name");

    clearTimeout(window.locationSearchTimeout);
    window.locationSearchTimeout = setTimeout(function () {
        if(jQ(elem).parent('div').find('input[name="college_id"]').length && jQ(elem).parent('div').find('input[name="college_id"]').val() != ""){
            jQ(elem).parent('div').find('input[name="college_id"]').val("");
            if(form_name.length){
                triggerEvent(document.getElementsByName(form_name)[0].college_id, 'change');
            }
        }

        var search_str = jQ(elem).val();
        if (search_str.length > 1 || jQ('input[value="find_dorms_by_college"]', form).length || jQ('input[value="find_campus_by_college"]', form).length) {
            ProcessLocationSearch(elem, search_str);
        } else {
            jQ("#location_container", form).fadeOut();
            jQ("#location_container", form).html("");
        }
    }, 400);
}

function ProcessLocationSearch(elem, search_str) {
    var dorm = false;

    if (jQ(elem).prop("tagName") === 'FORM') {
        var parent = elem;
    } else {
        var parent = jQ(elem).parents("form").get(0);
    }

    var type = ~~jQ(elem).attr("search-type");
    if(!type){
        type = ~~jQ('input[name="type"]', parent).val();
    }

    var preloader = jQ('.circle-preloader:not(.main-circle-preloader), .s_preload', parent).get(0);
    jQ('#location_filter_pr', parent).fadeIn(300);
    jQ(preloader).fadeIn(300);


    // Search Dorms at Scholarship Survey
    if (jQ('input[value="find_dorms_by_college"]', parent).length > 0) {
        dorm = '&scholarship=1&dorm=true&location_id='+jQ('input[value="find_dorms_by_college"]', parent).parents('.find-your-dorms-wrap').find('input[name=location_id]').val();
    } else if (jQ('input[value="find_campus_by_college"]', parent).length > 0) {
        dorm = '&scholarship=1&campus=true&location_id='+jQ('input[value="find_campus_by_college"]', parent).parents('.find-your-dorms-wrap').find('input[name=location_id]').val();
    }

//          if(window.location_search_xhr)
//                window.CodeAdapter.ajaxAbortRequest({ajax: window.location_search_xhr});
    window.location_search_xhr = window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: "action=get_location_search_json&s=" + encodeURIComponent(search_str) + '&type=' + type + (dorm ? dorm : ''),
        type: "POST",
        dataType: "json",
        success: function (json) {

            if (jQ(json.items[1]).length || jQ(json.items[2]).length || jQ(json.items[3]).length || jQ(json.items[4]).length || jQ(json.items[5]).length || jQ(json.items[7]).length || jQ(json.items[8]).length) {

                window.results_location_container = null;
                //if(!window.results_location_container)
                {
                    window.results_location_container = document.createElement('div');
                    window.results_location_container.className = "fast_search_location_results";
                }

                jQ(window.results_location_container).click(function (event) {
                    event.stopPropagation();
                });

                while (window.results_location_container.hasChildNodes()) {
                    window.results_location_container.removeChild(window.results_location_container.lastChild);
                }


                for (locion_type in json.items) {
                    if (
                        (jQ(json.items[1]).length && jQ(json.items[2]).length) ||
                        (jQ(json.items[1]).length && jQ(json.items[3]).length) ||
                        (jQ(json.items[1]).length && jQ(json.items[4]).length) ||
                        (jQ(json.items[1]).length && jQ(json.items[5]).length) ||

                        (jQ(json.items[2]).length && jQ(json.items[3]).length) ||
                        (jQ(json.items[2]).length && jQ(json.items[4]).length) ||
                        (jQ(json.items[2]).length && jQ(json.items[5]).length) ||

                        (jQ(json.items[3]).length && jQ(json.items[4]).length) ||
                        (jQ(json.items[3]).length && jQ(json.items[5]).length) ||

                        (jQ(json.items[4]).length && jQ(json.items[5]).length)
                    ) {
                        var item_container = document.createElement('div');
                        item_container.className = "fast_location_type";

                        var location_type_str = '';
                        switch (locion_type) {
                            case '1':
                                location_type_str = 'College/University';
                                break;
                            case '2':
                                location_type_str = 'City, State';
                                break;
                            case '3':
                                location_type_str = 'State';
                                break;

//                                                    case '5': location_type_str = 'Zip';
//                                                        break;
//                                                    case '6': location_type_str = 'Neighborhood';
//                                                        break;
                            case '7':
                                location_type_str = 'Dorms';
                                break;

                            case '8':
                                location_type_str = 'Apartments';
                                break;

                        }

                        item_container.innerHTML = location_type_str;

                        window.results_location_container.appendChild(item_container);
                    }

                    for (key in json.items[locion_type]) {

                        var item_container = document.createElement('div');
                        item_container.className = "fast_location_item";

                        item_container.setAttribute("default-type", json.items[locion_type][key]['TYPE']);
                        item_container.setAttribute("default-id", json.items[locion_type][key]['ID']);
                        item_container.setAttribute("default-url", json.items[locion_type][key]['URL']);
                        item_container.setAttribute("click-action", '1009');

                        var item = json.items[locion_type][key]['STR'];
                        item_container.innerHTML = item;

                        if (item_container.addEventListener) {
                            item_container.addEventListener('click', function () {
                                updateSearchLocation(this);
                            }, false);
                        }
                        else {
                            item_container.attachEvent('onclick', function (e) {
                                updateSearchLocation(e.srcElement);
                            });
                        }


                        window.results_location_container.appendChild(item_container);
                    }
                }


                var location_container = jQ(elem).parent("div").find("#location_container");
                if(!jQ(location_container).length){
                    location_container = jQ("#location_container", parent);
                }

                jQ(location_container).html('');
                jQ(location_container).append(window.results_location_container);

                if (jQ('.fast_search_location_results', location_container).length) {
                    jQ(location_container).fadeIn();
                }
                else {
                    jQ(location_container).fadeOut();
                }

            }
            else {
                var location_container = jQ(elem).parent("div").find("#location_container");
                if(!jQ(location_container).length){
                    location_container = jQ("#location_container", parent);
                }

                jQ(location_container).fadeOut();
            }

        },
        complete: function () {
            window.location_search_xhr = false;
            jQ(preloader).fadeOut(300);
            jQ('#location_filter_pr', parent).fadeOut(300);
        }
    });
}

function StartProcessPopupLocationSearch(elem) {
    clearTimeout(window.locationSearchTimeout);
    window.locationSearchTimeout = setTimeout(function () {
        var search_str = jQ(elem).val();
        if (search_str.length > 1) {
            ProcessPopupLocationSearch(elem, search_str);
        }
        else {
            jQ("#popup_location_container").fadeOut();
        }
    }, 400);
}

function ProcessPopupLocationSearch(elem, search_str) {
    var parent = jQ(elem).parents('form');
    var type = ~~jQ('input[name="type"]', parent).val();
    var preloader = jQ('#popup_location_input').parents('div').find('.popup_s_preload');

    jQ('#popup_location_filter_pr').fadeIn(300);
    jQ(preloader).fadeIn(300);


//          if(window.location_search_xhr)
//                window.CodeAdapter.ajaxAbortRequest({ajax: window.location_search_xhr});

    window.location_search_xhr = window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: "action=get_location_search_json&s=" + encodeURIComponent(search_str) + '&type=' + type,
        type: "POST",
        dataType: "json",
        success: function (json) {

            if (jQ(json.items[1]).length || jQ(json.items[2]).length || jQ(json.items[3]).length || jQ(json.items[4]).length || jQ(json.items[5]).length) {

                window.results_location_container = null;
                //if(!window.results_location_container)
                {
                    window.results_location_container = document.createElement('div');
                    window.results_location_container.className = "popup_fast_search_location_results";
                }

                jQ(window.results_location_container).click(function (event) {
                    event.stopPropagation();
                });

                while (window.results_location_container.hasChildNodes()) {
                    window.results_location_container.removeChild(window.results_location_container.lastChild);
                }

                for (locion_type in json.items) {
                    if (
                        (jQ(json.items[1]).length && jQ(json.items[2]).length) ||
                        (jQ(json.items[1]).length && jQ(json.items[3]).length) ||
                        (jQ(json.items[1]).length && jQ(json.items[4]).length) ||
                        (jQ(json.items[1]).length && jQ(json.items[5]).length) ||

                        (jQ(json.items[2]).length && jQ(json.items[3]).length) ||
                        (jQ(json.items[2]).length && jQ(json.items[4]).length) ||
                        (jQ(json.items[2]).length && jQ(json.items[5]).length) ||

                        (jQ(json.items[3]).length && jQ(json.items[4]).length) ||
                        (jQ(json.items[3]).length && jQ(json.items[5]).length) ||

                        (jQ(json.items[4]).length && jQ(json.items[5]).length)
                    ) {
                        var item_container = document.createElement('div');
                        item_container.className = "popup_fast_location_type";

                        var location_type_str = '';
                        switch (locion_type) {
                            case '1':
                                location_type_str = 'College/University';
                                break;
                            case '2':
                                location_type_str = 'City, State';
                                break;
                            case '3':
                                location_type_str = 'State';
                                break;

//                                                    case '5': location_type_str = 'Zip';
//                                                        break;
//                                                    case '6': location_type_str = 'Neighborhood';
//                                                        break;

                        }

                        item_container.innerHTML = location_type_str;

                        window.results_location_container.appendChild(item_container);
                    }

                    for (key in json.items[locion_type]) {

                        var item_container = document.createElement('div');
                        item_container.className = "popup_fast_location_item";

                        item_container.setAttribute("default-type", json.items[locion_type][key]['TYPE']);
                        item_container.setAttribute("default-id", json.items[locion_type][key]['ID']);
                        item_container.setAttribute("default-url", json.items[locion_type][key]['URL']);

                        var item = json.items[locion_type][key]['STR'];
                        item_container.innerHTML = item;

                        if (item_container.addEventListener) {
                            item_container.addEventListener('click', function () {
                                var form = jQ(this).parents('form');
                                jQ('input[name="location"]', form).val(jQ(this).text());
                                jQ('input[name="location_type"]', form).val(jQ(this).attr('default-type'));
                                jQ('input[name="location_value"]', form).val(jQ(this).attr('default-id'));
                                jQ('input[name="location_url"]', form).val(jQ(this).attr('default-url'));
                                jQ('#search_location_btn', form).removeClass('active');
                                jQ('#popup_location_container', form).slideUp(300);
                            }, false);
                        }
                        else {
                            item_container.attachEvent('onclick', function (e) {
                                var form = jQ(e.srcElement).parents('form');
                                jQ('input[name="location"]', form).val(jQ(e.srcElement).text());
                                jQ('input[name="location_type"]', form).val(jQ(e.srcElement).attr('default-type'));
                                jQ('input[name="location_value"]', form).val(jQ(e.srcElement).attr('default-id'));
                                jQ('input[name="location_url"]', form).val(jQ(e.srcElement).attr('default-url'));
                                jQ('#search_location_btn', form).removeClass('active');
                                jQ('#popup_location_container', form).slideUp(300);
                            });
                        }


                        window.results_location_container.appendChild(item_container);
                    }
                }

                document.getElementById("popup_location_container").innerHTML = '';
                document.getElementById("popup_location_container").appendChild(window.results_location_container);

                if (jQ('#popup_location_container .popup_fast_search_location_results').length) {
                    jQ("#popup_location_container").fadeIn();
                }
                else {
                    jQ("#popup_location_container").fadeOut();
                }

            }
            else {
                jQ("#popup_location_container").fadeOut();
            }

        },
        complete: function () {
            window.location_search_xhr = false;
            jQ(preloader).fadeOut(300);
            jQ('#popup_location_filter_pr').fadeOut(300);
        }
    });
}

jQ.fn.scrollTo = function (target) {
    return this.each(function () {
//        if(scrollToAminate)
//            return false;

        scrollToAminate = true;
        var container = jQ(this);
        var scrollTo = jQ(target, container);

        container.animate({scrollTop: scrollTo.offset().top - container.offset().top + container.scrollTop()}, 100, function () {
            scrollToAminate = false;
        })

    });
}


function getListingtimeline() {
    return '<div class="listing-timeline-item">\
            <div class="listing-timeline-animated-background">\
            <div class="listing-timeline-background-masker header-top"></div>\
            <div class="listing-timeline-background-masker header-left"></div>\
            <div class="listing-timeline-background-masker header-right"></div>\
            <div class="listing-timeline-background-masker header-bottom"></div>\
            <div class="listing-timeline-background-masker subheader-left"></div>\
            <div class="listing-timeline-background-masker subheader-right"></div>\
            <div class="listing-timeline-background-masker subheader-bottom"></div>\
            <div class="listing-timeline-background-masker content-first-line-left"></div>\
            <div class="listing-timeline-background-masker content-first-line-right"></div>\
            <div class="listing-timeline-background-masker content-first-line-bottom"></div>\
            <div class="listing-timeline-background-masker content-second-line-left"></div>\
            <div class="listing-timeline-background-masker content-second-line-right"></div>\
            <div class="listing-timeline-background-masker content-second-line-bottom"></div>\
            <div class="listing-timeline-background-masker content-third-line-left"></div>\
            <div class="listing-timeline-background-masker content-third-line-right"></div>\
            <div class="listing-timeline-background-masker content-third-line-bottom"></div>\
            <div class="listing-timeline-background-masker content-fourth-line-left"></div>\
            <div class="listing-timeline-background-masker content-fourth-line-right"></div>\
            <div class="listing-timeline-background-masker content-fourth-line-bottom"></div>\
            </div>\
            </div>';
}

function updateSearchInput(e, input) {
    var form = jQ(e).parents('form');
    jQ(input).val(jQ(e).text()).change();
}

//############### Update Search Location ##############
function updateSearchLocation(e) {
    trackClickAction(e);

    var form = jQ(e).parents('form');
    var form_name = jQ(form).attr("name");

    jQ(e).parents("#location_container").parent('div').find('input[name="location"], #user-hometown, #user-college').val(jQ(e).text()).trigger('change');

    if (jQ(e).parents('.find-your-dorms-wrap').find('input[name="scholarship_search"]').length) {
        requirejs(['pages/UloopScholarship'], function (obj) {
            obj.instance.scholarshipSearchLocation(e);
        });
    }
    // jQ('input[name="location"], #user-hometown, #user-college', form).val(jQ(e).text());


    if(jQ(e).parents("#location_container").parent('div').find('input[name="college_id"]').length){
        jQ(e).parents("#location_container").parent('div').find('input[name="college_id"]').val(jQ(e).attr('default-id'));
        if(form_name.length && typeof document.getElementsByName(form_name)[0].college_id !== typeof undefined) {
            triggerEvent(document.getElementsByName(form_name)[0].college_id, 'change');
        }

    } else if (jQ(e).parents('.find-your-college-wrap').siblings('.find-your-dorms-wrap').find('input[name="location_id"]').length) {
        jQ(e).parents('.find-your-college-wrap').siblings('.find-your-dorms-wrap').find('input[name="location_id"]').val(jQ(e).attr('default-id'));

        if (jQ('.find-your-dorms-wrap').hasClass("in-active")) {
            jQ('.find-your-dorms-wrap').removeClass("in-active");
        }

    } else if (jQ('.sign-up-form input[name="location_id"]').length) {
        jQ('.sign-up-form input[name="location_id"]').val(jQ(e).attr('default-id'));

    } else {
        jQ('input[name="location_id"]', form).val(jQ(e).attr('default-id'));
    }

    if(window.location.host.split('.')[0] === 'www'){
        if(window.location.pathname === '/'){
            sendGoogleAnalytics('event', 'Student selected school', 'Homepage “School Selection”', 'from ' + window.location.href);
        } else {
            sendGoogleAnalytics('event', 'Student selected school', 'Category page “School Selection”', 'from ' + window.location.href);
        }
    }

    jQ('input[name="location_type"]', form).val(jQ(e).attr('default-type'));
    jQ('input[name="location_url"]', form).val(jQ(e).attr('default-url')).change();
    jQ('#search_location_btn', form).removeClass('active');
    jQ(e).parents("#location_container").slideUp(300);


    if (window.autoSearchAds) {
        // StartProcessSearchAds(false);
        setURL('', '', housingListViewLink(true));
        if (typeof(jQ('#location_container_wrap', form)) !== 'undefined' && jQ('#location_container_wrap').is(":visible")) {
            jQ('#search_location_btn').removeClass('active');
            jQ('#location_container_wrap').slideUp(300);
            HideFilters();
        }
    }
    else {
        //jQ('#location_container', form).hide(0);
    }

    // jQ(e).parent(".fast_search_location_results").remove(); ?? with it popup closed after select location
}

function errorTextBookCover(elem) {
    jQ(elem).attr('src', window.imagesDomain + '/img/no-image-small.jpg');
}

function errorTextBookRecentlySold(elem) {
    jQ(elem).closest('.textbook').css({ 'padding-left' : '10px' });
    jQ(elem).closest('.tb-img').remove();
}

function trackListingView(listingID) {

    var urlSchema = urlObject();

    var additional_params = {};
    if (urlSchema.parameters.tlink) {
        additional_params.partner_code = urlSchema.parameters.tlink;
    }

    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        window.CodeAdapter.ajaxRequest({
            url: "ajax_content.php",
            data: 'action=tracking_visits_ads&listingID=' + listingID + ( additional_params ? '&' + jQuery.param(additional_params) : '' ),
            dataType: "html",
            type: "POST",
            cache: "false",
            success: function (data) {

            }
        });
    }
}

function trackTransitionFromEmail(outID) {

    var urlSchema = urlObject();

    var link = '//' + urlSchema.host + urlSchema.pathname + urlSchema.search;

    window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: 'action=add_transitions_from_email&link=' + encodeURIComponent(link) + '&outID=' + outID,
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {

        }
    });
}


function CheckFavorite() {
    if (CrossStorage === null) {
        return false;
    }

    CrossStorage.get('hotlist', function (data) {

        var hotlist_sync = jQ.cookie("hotlist_sync");
        if (typeof(hotlist_sync) !== 'undefined' && hotlist_sync) {
            hotlistSync(data);
            jQ.cookie('hotlist_sync', null, {path: '/', domain: COOKIE_DOMAIN});
        }

        CheckFavoriteCallback(data);
    });
}


function CheckFavoriteCallbackIndex(hotlist) {

    if (typeof(hotlist) === 'undefined' || !hotlist)
        return false;

    hotlistArray = JSON.parse(hotlist);
    if (hotlistArray) {
        if (jQ('.user-hotlist .count').get(0)) {
            if (hotlistArray.length) {
                jQ('.user-hotlist .count').html(hotlistArray.length);
            } else {
                jQ('.user-hotlist .count').html('');
            }
        }
        for (var key in hotlistArray) {
            jQ('.index-post .hotlist[parent_id="' + ~~hotlistArray[key].parent_id + '"][type="' + ~~hotlistArray[key].type + '"]').addClass('added').html('<svg class="favorites-icon ignore-click-redirect"><use xlink:href="/img/svg/uloop.svg#star-filled"></use></svg>').attr('title', 'Remove from Favorites');
        }
        fixSvgIE9(document, {});
    }
}

function CheckFavoriteIndex() {
    if (CrossStorage === null) {
        return false;
    }

    CrossStorage.get('hotlist', function (data) {

        var hotlist_sync = jQ.cookie("hotlist_sync");
        if (typeof(hotlist_sync) !== 'undefined' && hotlist_sync) {
            hotlistSync(data);
            jQ.cookie('hotlist_sync', null, {path: '/', domain: COOKIE_DOMAIN});
        }

        CheckFavoriteCallbackIndex(data);
    });
}


function hotlistSync(hotlist, callback) {
    window.CodeAdapter.ajaxRequest({
        url: 'ajaxapi.php',
        data: 'action=hotlist_sync&hotlist=' + hotlist,
        type: "POST",
        async: false,
        dataType: "text",
        success: function (data) {
            if (data && typeof(data) !== 'undefined') {
                data = JSON.parse(data);

                hotlist = [];

                for (var key in data) {
                    var hotlistRow = {
                        listing_id: data[key].listing_id,
                        type: ~~data[key].type,
                        parent_id: data[key].parent_id
                    };
                    hotlist.push(hotlistRow);
                }

                CrossStorage.set({
                    hotlist: JSON.stringify(hotlist)
                });

                if (typeof(callback) !== 'undefined') {
                    callback(JSON.stringify(hotlist));
                }
            }

        }
    });
}

function CheckFavoriteCallback(hotlist) {

    if (typeof(hotlist) === 'undefined' || !hotlist)
        return false;

    hotlistArray = JSON.parse(hotlist);
    if (hotlistArray) {
        for (var key in hotlistArray) {
            jQ('.hotlist[type="' + ~~hotlistArray[key].type + '"][parent_id="' + ~~hotlistArray[key].parent_id + '"]').addClass('added').html('<svg class="favorites-icon ignore-click-redirect"><use xlink:href="/img/svg/uloop.svg#star-filled"></use></svg>').attr('title', 'Remove from Favorites');
        }
        fixSvgIE9(document, {});
    }
}


window.pri = function (elem) {
    jQ(elem).attr('src', window.imagesDomain + '/img/no-image-small2.jpg');
    jQ(elem).parents('.post').find('.post_count_image').remove();
};


String.prototype.capitalize = function () {
    return this.toLowerCase().replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase();
    });
};

String.prototype.width = function(font) {
    var f = font || '12px arial',
        o = jQ('<div></div>')
            .text(this)
            .css({'position': 'absolute', 'float': 'left', 'white-space': 'nowrap', 'visibility': 'hidden', 'font': f})
            .appendTo(jQ('body')),
        w = o.width();

    o.remove();

    return w;
}

function LoadFavorites(page) {
    if (CrossStorage === null) {
        return false;
    }

    CrossStorage.get('hotlist', function (data) {
        if (window.CodeAdapter.project_type == 'wordpress_plugin') {
            hotlistSync(data, LoadFavoritesCallback_wordpress_plugin);
        }
        else {
            LoadFavoritesCallback(data, page);
        }
    });
}

function LoadFavoritesCallback(hotlist, page) {
    if (typeof(hotlist) === 'undefined' || !hotlist) {
        hotlist = '[]';
    }

    jQ.ajax({
        url: 'ajaxapi.php',
        data: 'action=get_favorites&page=' + page + '&hotlist=' + hotlist,
        type: "POST",
        async: false,
        dataType: "html",
        success: function (data) {
            jQ('#classifieds_result .search_section_title', window.MainWindowDocument).after(data);

            jQ('.pt_11').each(function () {
                var link = jQ(this).find('a.title').attr('href');
                jQ(this).find('a.answer-btn').attr('href', link);
            });

            addErrorIMGEvent();
            jQ('.cutText').cutTextToLines();
            processUloopExternalLinks();
            UloopListPageSlider.SliderInit();

            jQ('#hotlist_preloader').hide();
            CheckFavorite();
            // processImagesScale();
        }
    });
}

function DetailCheckFavorite() {
    if (CrossStorage === null) {
        return false;
    }

    CrossStorage.get('hotlist', function (data) {
        DetailCheckFavoriteCallback(data);
    });
}

function DetailCheckFavoriteCallback(hotlist) {
    if (typeof(hotlist) === 'undefined' || !hotlist)
        return false;

    hotlistArray = JSON.parse(hotlist);
    if (hotlistArray) {
        var type = jQ('.title-container .hotlist').attr('type');
        var parent_id = jQ('.title-container .hotlist').attr('parent_id');

        for (var key in hotlistArray) {
            if ((~~hotlistArray[key].parent_id) == parent_id && (~~hotlistArray[key].type) == type) {
                jQ('.title-container .hotlist ').addClass('added').html('<svg class="favorites-icon ignore-click-redirect"><use xlink:href="/img/svg/uloop.svg#star-filled"></use></svg>').attr('title', 'Remove from Favorites');
                fixSvgIE9(document, {});
                break;
            }
        }
    }
}


function getParameterFromURL(name, url) {
    if (!url) url = location.href;
    name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var regexS = "[\\?&]" + name + "=([^&#]*)";
    var regex = new RegExp(regexS);
    var results = regex.exec(url);
    return results == null ? null : results[1];
}

function ShowSignUpPopup(e) {
    e.preventDefault();
    e.stopPropagation();
    ShowPopup('sgn_up_popup');
}

function confirmSubscriptionAction(id, checked, confirm, callback) {
    if (!id) {
        closePopup('custom_preloader');
        return;
    }

//    closePopup('confirm_ad_unsubscribe');

    window.CodeAdapter.ajaxRequest({
        url: "profile.htm",
        data: 'action=profile_change_auto_renew&id=' + id + '&auto_renew=' + checked + '&confirmed=' + confirm,
        type: "POST",
        dateType: "html",
        success: function (data) {
            jQ('#subsc_' + id + ' .ch_auto_renew').prop('disabled', true);

            if (!jQ('#confirm_ad_unsubscribe').length) {
                var div = '<div class="super_popup" id="confirm_ad_unsubscribe">' +
                    '<div class="text_local_storage">' +
                    '<h2></h2>' +
                    '<div class="button_wrap" style="display: none;">' +
                    '</div>' +
                    '</div>' +
                    '</div>';

                jQ('body').append(div);
            }

            if (data)
                var data = JSON.parse(data);
            else
                return false;

            if (data.confirm) {
                jQ('#confirm_ad_unsubscribe .button_wrap').html('<a href="javascript:;" class="orange_button_h_36 color_white" onclick="confirmSubscriptionAction(\'' + id + '\',\'' + checked + '\',\'1\',' + callback + '); return false;">OK</a><a href="javascript:;" class="orange_button_h_36 color_white" onclick="closePopup(\'confirm_ad_unsubscribe\'); return false;">No Thanks</a>').show();
            }
            else {
                jQ('#confirm_ad_unsubscribe .button_wrap').html('');
            }

            if (data.msg) {
                jQ('#confirm_ad_unsubscribe h2').html(data.msg);
                ShowPopup('confirm_ad_unsubscribe');
            }

            jQ('#subscription_historys_preload').hide();

            if (data.action) {
                jQ('#subsc_' + id + ' .ch_auto_renew').prop('checked', ~~checked ? true : false);

                if (typeof(callback) === 'function') {
                    callback();
                }
            }

            jQ('#subsc_' + id + ' .ch_auto_renew').prop('disabled', false);


        }
    });
    return false;
}

function SetLisitngToAutoRenew(e, post_id, action) {
    e.preventDefault();
    e.stopPropagation();

    ShowPopup('custom_preloader');

    window.CodeAdapter.ajaxRequest({
        url: 'ajaxapi.php',
        data: 'action=ajax_set_listing_to_auto_renew&post-id=' + ~~post_id + '&renew-action=' + action,
        dataType: "json",
        success: function (data) {
            if (data.success && data.sid) {
                confirmSubscriptionAction(data.sid, 1, 0, SetLisitngToAutoRenewCallBack);
            }
            else {
                closePopup('custom_preloader');
            }
        }
    });
    return false;
}

function SetLisitngToAutoRenewCallBack() {
    setTimeout(function () {
        location.reload();
    }, 2000);
}

function trackUloopClick(action, ei, fp, tp, pb, chi) {

    window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: 'action=' + action + '&' + 'ei=' + ei + '&fp=' + fp + '&tp=' + tp + '&pb=' + pb + '&chi=' + chi,
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {

        }
    });
}



function triggerConversionGoal(goal, params) {
    window.CodeAdapter.ajaxRequest({
        url: '/ajaxapi.php',
        data: 'trigger_goal=1&goal=' + goal + '&' + jQ.param(params),
        cache: "false",
        success: function (data) {

        }
    });
}

function storagePhoneToggle(event, elem, phone) {
    if (!window.isMobile.any()) {
        jQ(elem).html(phone).removeAttr('onclick');
        event.preventDefault();
        return false;
    }
}

function CallChangeSelectEvent(event, elem) {
    if (elem.options[elem.selectedIndex].value == 0) {
        elem.setAttribute('status', '');
    } else {
        elem.setAttribute('status', 'active');
    }

}

function processUloopExternalLinks() {
    jQ("*[view_external_link]").each(function () {
        if (!jQ(this).hasClass('hidden-tracking') || jQ(this).attr('href') == '#') {
            jQ(this).attr('href', '/view_external_link.php?' + jQ(this).attr('view_external_link'));
            if (jQ(this).attr('target') !== '_self') {
                jQ(this).attr('rel', 'nofollow');
                jQ(this).attr('target', '_blank');
            }

            if(jQ(this).hasClass('open-popup-window')){
                if(jQ(this).attr('data')){
                    var pw_size = jQ(this).attr('data').split('x');
                    var pw_width = ~~pw_size[0];
                    var pw_height = ~~pw_size[1];
                }

                var pw_width = pw_width || 300;
                var pw_height = pw_height || 300;

                jQ(this).attr('onclick', "WindowPopupCenter('"+jQ(this).attr('href')+"', '', "+pw_width+","+pw_height+"); return false;");
            }
        }
    });
}

function WindowPopupCenter(url, title, w, h) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
    var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

    var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    var left = ((width / 2) - (w / 2)) + dualScreenLeft;
    var top = ((height / 2) - (h / 2)) + dualScreenTop;
    var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
}

function showProgressPreloader() {
    jQ("#progress", window.MainWindowDocument).width((3 + Math.random() * 30) + "%").fadeIn(100);
    jQ("#loader-overlay").css('background-color', '#fff').fadeIn(300);
}

/************ START Tickets Detail Page ************/
function LoadTicketsDetail(hash) {
    window.CodeAdapter.ajaxRequest({
        url: '/ajax_content.php',
        data: 'action=tickets_detail&hash=' + hash,
        dataType: "json",
        type: "POST",
        cache: "false",
        success: function (data) {
            if (typeof(data) !== 'undefined') {
                tickets = data.tickets;
                images_paths = data.images_paths;
                paths = data.paths;
                paths_x1 = data.paths_x1;
                paths_x2 = data.paths_x2;
                paths_y1 = data.paths_y1;
                paths_y2 = data.paths_y2;
            }

            buildTicketsDetail();
        }
    });
}

function buildTicketsDetail() {
    if (typeof UloopCanvas === 'function' && venue_canvas !== null && (typeof venue_canvas.getContext === 'function')) {
        window.UloopTicketsCanvas = new UloopCanvas();
        UloopTicketsCanvas.Init({
            canvas: venue_canvas,
            paths: paths,
            images_paths: images_paths,
            paths2DPos: {x1: paths_x1, y1: paths_y1, x2: paths_x2, y2: paths_y2},
            clickCallback: showSectionTickets,
            pathDrawType: 'behind',
            fillOpacity: '0.4',
            activeFillStyle: '#da621a',
            hoverFillStyle: '#37d837',
        });

        ResizeTicketsDetailPage(venue_canvas);

        // Add resize event listener.
        on(window, "resize", function (e) {
            ResizeTicketsDetailPage(venue_canvas);
        });

        on(window, "scroll", function (e) {
            UpdateTicketsCanvasOffset(venue_canvas);
        });
    }

    var tickets_container = jQ("#tickets-wrap");
    jQ(tickets_container).html('');

    if (typeof(tickets) !== 'undefined' && tickets.length > 0) {
        var tickets_content = '<div class="ticket-info-row t-row title-row">\
                            <div class="section t-cell sub-heading">Section</div>\
                            <div class="row t-cell sub-heading">Row</div>\
                            <div class="buy t-cell sub-heading">Buy Tickets</div>\
                        </div>';

        for (var key in tickets) {
            tickets_content += buildTicketHtml(tickets[key]);
        }
        jQ(tickets_container).append(tickets_content);

        if (window.CodeAdapter.project_type == 'wordpress_plugin') {
            processExternalLinks();
        } else {
            processUloopExternalLinks();
        }
    } else {
        var tickets_content = '<div class="ticket-info-row t-row title-row">\
                           0 tickets left\
                        </div>';
        jQ(tickets_container).append(tickets_content);
    }

    jQ("#progress", window.MainWindowDocument).width("100%").delay(100).fadeOut(300);
    jQ("#loader-overlay").delay(150).fadeOut(800);

    if (venue_canvas !== null && (typeof venue_canvas.getContext === 'function')) {
        jQ('body').on('mouseover', '.ticket-info-row', function (e) {
            UloopTicketsCanvas.clearHoverEffects();
            UloopTicketsCanvas.setActiveSecrionPath2D(jQ(this).attr('sec'));
            UloopTicketsCanvas.applyHoverEffects();
            UloopTicketsCanvas.drawCanvas();
        });

        jQ('body').on('click', '#show-all-tickets', function (e) {
            e.preventDefault();
            UloopTicketsCanvas.clearClickedItems();

            var descMain = jQ('#desc-main');
            var descAlternative = jQ('#desc-alternative');

            descMain
            //.animate({left: '0',opacity:'1' })
                .fadeIn(0);

            descAlternative
            // .animate({ left: '-400px', opacity:'0.4' })
                .fadeOut(0);
        });
        jQ('.event-page-right .map-control').css('visibility', 'visible');
    }
}

function showSectionTickets(section) {
    var sec = section['sec'],
        descMain = jQ('#desc-main'),
        descAlternative = jQ('#desc-alternative');

    descMain
    // .animate({  left: '-400px', opacity:'0.4' })
        .fadeOut(0);

    descAlternative
    //.animate({ left: '0', opacity:'1' })
        .fadeIn(0);

    var _tickets = tickets.filter(function (ticket) {
        if (ticket['section'] === undefined) {
            throw  Error('ticket[\'section\'] is not defined!')
        }
        return ticket['section'] == sec;
    });

    var innerHtml = ' <div id="tickets-wrap">\
                                    <div class="show-all-tickets-block">\
                                        <a href="#!" id="show-all-tickets" class="show-all-tickets-btn"> <i class="arrow-left"></i> Show All Tickets</a>\
                                    </div>\
                                    <div class="ticket-info-row t-row title-row">\
                                        <div class="section t-cell h2">Section</div>\
                                        <div class="row t-cell h2">Row</div>\
                                        <div class="buy t-cell"></div>\
                                </div>';

    for (var key in _tickets) {
        innerHtml += buildTicketHtml(_tickets[key]);
    }

    innerHtml += '</div>';

    descAlternative.html(innerHtml).fadeIn(500);

    if (window.CodeAdapter.project_type == 'wordpress_plugin') {
        processExternalLinks();
    } else {
        processUloopExternalLinks();
    }
}


function buildTicketHtml(data) {
    var content = '';
    content += '<div class="ticket-info-row t-row" sec="' + data.section + '">\
                            <div class="section t-cell">\
                                <div class="section-name">' + data.sectionName + '</div>\
                                <div class="quantity-tickets">' + data.quantity + ' ticket' + (data.quantity == 1 ? '' : 's') + '</div>\
                            </div>\
                        <div class="row t-cell">' + data.row + '</div>\
                            <div class="buy t-cell">\
                                <a ' + data.href + ' class="btn btn-orange">$' + data.price + ' / ea</a>\
                            </div>\
                        </div>';

    return content;
}

function UpdateTicketsCanvasOffset(canvas) {
    var canvasOffset = jQ(canvas).offset();
    UloopTicketsCanvas.setOffsetX(canvasOffset.left - ~~jQ(window).scrollLeft());
    UloopTicketsCanvas.setOffsetY(canvasOffset.top - ~~jQ(window).scrollTop());
}

function ResizeTicketsDetailPage(canvas) {
    var canvasOffset = jQ(canvas).offset();
    UloopTicketsCanvas.setOffsetX(canvasOffset.left - ~~jQ(window).scrollLeft());
    UloopTicketsCanvas.setOffsetY(canvasOffset.top - ~~jQ(window).scrollTop());
    UloopTicketsCanvas.activateAlignByCenter();
    UloopTicketsCanvas.setCanvasSize(jQ('.venue-canvas-container').width(), jQ('.venue-canvas-container').height());
    UloopTicketsCanvas.alignCenter();
    UloopTicketsCanvas.drawCanvas();
}
/************ END Tickets Detail Page ************/




function RealtorWidgetInit() {
    window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: 'action=getRealrotWidget',
        dataType: "html",
        type: "POST",
        success: function (data) {
            if (typeof(data) !== 'undefined' && data.length) {
                jQ("#realtor_widget_ajax").replaceWith(data);
                jQ('#right_content #realtor_sidebar_widget').fadeIn(3000);
            }
        }
    });
}


function Trigger_Error(elm, txt, scroll) {
    var elm = jQ(elm).get(0);
    var elem_id = jQ(elm).attr('name');
    var parent = jQ(elm).parents('.input-wrap, .wrap, .custom_fields_block');
    var error_label = jQ('[id="' + elem_id + '-error"][disabled!="disabled"]', parent).get(0);
    if (typeof(error_label) === "undefined") {
        error_label = jQ('[id="' + jQ(elm).attr('id') + '-error"][disabled!="disabled"]', parent).get(0);
    }
    if (typeof(elm) === "undefined" || typeof(error_label) === "undefined")
        return false;

    if (typeof(txt) === "undefined") {
        if (elm.addEventListener) {
            elm.addEventListener('keydown', function () {
                jQ(elm).removeClass('validate_error');
                error_label.style.visibility = 'hidden';
                this.keydown = null;
            }, false);
        }
        else if (elm.attachEvent) {
            elm.attachEvent("onkeydown", function () {
                jQ(elm).removeClass('validate_error');
                this.keydown = null;
                error_label.style.visibility = 'hidden';
            });
        }

    }
    else if (txt.length <= 0) {
        txt = jQ(error_label).text();

        if (typeof(txt) === "undefined" && txt.length <= 0)
            return false;

        if (elm) {
            addCustomEventHandle(elm, error_label, 'keydown', false);
            addCustomEventHandle(elm, error_label, 'change', false);
        }
    }
    else {

        SetErrorText(error_label, txt, scroll);

        if (elm) {
            addCustomEventHandle(elm, error_label, 'keydown', false);
            addCustomEventHandle(elm, error_label, 'change', false);
        }
    }

    if (error_label)
        BlinkElem(error_label);

    jQ(elm).addClass('validate_error');

    if (elm)
        elm.focus();

    return elm;
}
function SetErrorText(elm, txt, scroll) {
    elm.innerHTML = txt;

    var selectedPosX = 0;
    var selectedPosY = 0;

    BlinkElem(elm);

    if (scroll && typeof(txt) !== "undefined") {
        while (elm != null) {
            selectedPosX += elm.offsetLeft;
            selectedPosY += elm.offsetTop;
            elm = elm.offsetParent;
        }

        jQ('html, body').animate({scrollTop: selectedPosY - 50}, 100);
    }

    return elm;
}

BlinkElem = function (elm, count, timeDelay) {
    var count = count || 3;
    var blinks = 0;

    function blink_f() {
        blinks++;
        elm.style.visibility = blinks % 2 == 0 && blinks != count * 2 ? 'visible' : 'hidden';
        if (blinks < count * 2) {
            if (timeDelay) {
                if (blinks === 1)
                    setTimeout(blink_f, 450);
                else
                    setTimeout(blink_f, 200);
            }
            else
                setTimeout(blink_f, 100);

        }
        else
            elm.style.visibility = 'visible';
    };
    blink_f();
    popup_error();
}


function removeCustomEventHandle(elem, event) {
    if (elem.removeEventListener) {
        elem.removeEventListener(event, addCustomEventHandleFunction, true);
    } else if (elem.detachEvent) {
        elem.detachEvent("on" + event, addCustomEventHandleFunction);
    }
}

function addCustomEventHandleFunction(evt) {
    if (evt.target.elem_error) {
        jQ(evt.target).removeClass('validate_error');

        if (typeof(evt.target.clear_error) === 'undefined' || evt.target.clear_error)
            evt.target.elem_error.innerHTML = '';
        else
            evt.target.elem_error.style.visibility = 'hidden';
        this.keydown = null;
    }
}

function addCustomEventHandle(elem, elem_error, event, clear_error) {
    elem.elem_error = elem_error;
    elem.clear_error = clear_error;

    if (elem.addEventListener) {
        elem.addEventListener(event, addCustomEventHandleFunction, true);
    }
    else if (elem.attachEvent) {
        elem.attachEvent("on" + event, addCustomEventHandleFunction);
    }
}

function Validate_Data(data, scroll, txt) {

    var Trigger_Error = function (elm, txt, scroll, skipfocus) {

        if (typeof skipfocus === typeof undefined) {
            skipfocus = false;
        }

        var elm = jQ(elm).get(0);
        var elem_id = jQ(elm).attr('name');
        var parent = jQ(elm).parents('.wrap');
        var error_label = jQ('[id="' + elem_id + '-error"][disabled!="disabled"]', parent).get(0);
        if (typeof(error_label) === "undefined") {
            error_label = jQ('[id="' + jQ(elm).attr('id') + '-error"][disabled!="disabled"]', parent).get(0);
        }

        if (typeof(elm) === "undefined" || typeof(error_label) === "undefined")
            return false;


        if (typeof(txt) === "undefined") {
            if (elm.addEventListener) {
                elm.addEventListener('keydown', function () {
                    jQ(elm).removeClass('validate_error');
                    error_label.style.visibility = 'hidden';
                    this.keydown = null;
                }, false);
            }
            else if (elm.attachEvent) {
                elm.attachEvent("onkeydown", function () {
                    jQ(elm).removeClass('validate_error');
                    this.keydown = null;
                    error_label.style.visibility = 'hidden';
                });
            }

        }
        else if (txt.length <= 0) {
            txt = jQ(error_label).text();

            if (typeof(txt) === "undefined" && txt.length <= 0)
                return false;

            if (elm) {
                addCustomEventHandle(elm, error_label, 'keydown', false);
                addCustomEventHandle(elm, error_label, 'change', false);
            }
        }
        else {

            SetErrorText(error_label, txt, scroll);

            if (elm) {
                addCustomEventHandle(elm, error_label, 'keydown', false);
                addCustomEventHandle(elm, error_label, 'change', false);
            }
        }

        if (error_label)
            BlinkElem(error_label);

        jQ(elm).addClass('validate_error');

        if (elm && !skipfocus)
            elm.focus();

        return elm;
    }

    var valid = true;

    jQ(data).each(function (index, elem) {
        if (!jQ(elem).hasClass('valid'))
            return;

        var tag = jQ(elem).prop("tagName");
        var elemName = jQ(elem).attr('name');
        var parent = jQ(elem).parents('.wrap')
        var txt_error = '';

        if (typeof(txt) !== 'undefined') {
            txt_error = txt;
        } else if (typeof(jQ('#' + elemName + '-error', parent).attr('default')) !== 'undefined' && jQ('#' + elemName + '-error', parent).attr('default').length) {
            txt_error = jQ('#' + elemName + '-error', parent).attr('default');
        } else if (typeof(jQ('#' + elemName + '-error', parent).attr('data')) !== 'undefined' && jQ('#' + elemName + '-error', parent).attr('data').length) {
            txt_error = jQ('#' + elemName + '-error', parent).attr('data');
        }

        switch (tag) {
            case 'INPUT':
                if (jQ(elem).attr('type') == 'radio') {
                    if(!jQ('input[name="'+elemName+'"]', parent).is(':checked')){
                        jQ(elem).focus();
                        Trigger_Error(elem, txt_error, scroll);
                        valid = false;
                        return false;
                    }
                    break;
                } else if (jQ(elem).hasClass('NumGroup')) {
                    var groupClass = jQ(elem).attr("default-gn");
                    var groupValidateFlag = true;
                    if (typeof(groupClass) == 'undefined')
                        return;

                    var groupData = jQ('.' + groupClass, parent);
                    jQ(groupData).each(function (groupDataIndex, groupDataElem) {
                        var groupElemLength = ~~jQ(groupDataElem).attr('maxlength');
                        var groupElemValLength = ~~jQ(groupDataElem).val() > 0 ? jQ(groupDataElem).val().length : 0;

                        if (groupElemValLength == 0 || groupElemValLength < groupElemLength) {
                            jQ(elem).focus();
                            Trigger_Error(elem, '', scroll);
                            jQ("body").on("change", "." + groupClass, function () {
                                if (jQ('#' + elemName + '-error'))
                                    jQ('#' + elemName + '-error').css({'visibility': 'hidden'});
                            });
                            groupValidateFlag = false;
                            valid = false;
                            return false;
                        }
                    });
                    return groupValidateFlag;
                }
                else if (jQ(elem).val().length == 0 || CMPS_Trim(jQ(elem).val()).length == 0) {
                    if (!jQ(elem).hasClass('datepicker')) {
                        jQ(elem).focus();
                    }
                    Trigger_Error(elem, txt_error, scroll, jQ(elem).hasClass('datepicker'));
                    valid = false;
                    return false;
                }
                else if (CMPS_hasHTMLTags(jQ(elem).val())) {
                    jQ(elem).focus();
                    Trigger_Error(elem, 'Please enter text only', scroll);
                    valid = false;
                    return false;
                }
                else if (jQ(elem).hasClass('email') && !isValidEmailAddress(jQ(elem).val())) {
                    jQ(elem).focus();
                    Trigger_Error(elem, 'Please enter valid Email', scroll);
                    valid = false;
                    return false;
                }
                else if (jQ(elem).hasClass('edu-email') && !ReplyByEmailWhiteList(jQ(elem).val())) {
                    var email_domain = jQ(elem).val().substring(jQ(elem).val().indexOf('@') + 1);
                    var email_domain_parts = email_domain.split('.');

                    if (email_domain_parts.indexOf('edu') === -1) {
                        jQ(elem).focus();

                        var error_label = jQ('[id="' + elemName + '-error"][disabled!="disabled"]', parent).get(0);
                        if (typeof(error_label) != 'undefined') {
                            removeCustomEventHandle(jQ(elem).get(0), 'keydown');
                            removeCustomEventHandle(jQ(elem).get(0), 'change');

                            SetErrorText(error_label, 'Please enter a .edu email address', scroll);
                        } else {
                            Trigger_Error(elem, 'Please enter a .edu email address', scroll);
                        }

                        valid = false;
                        return false;
                    }
                }
                else if (jQ(elem).hasClass('num') && !isInteger(jQ(elem).val())) {
                    jQ(elem).focus();
                    Trigger_Error(elem, 'Please enter only numbers', scroll);
                    valid = false;
                    return false;
                }
                else if (jQ(elem).hasClass('phone') && !isValidPhone(jQ(elem).val())) {
                    // console.log(jQ(elem).val());
                    jQ(elem).focus();
                    Trigger_Error(elem, 'Please enter valid Phone Number', scroll);
                    valid = false;
                    return false;
                }
                break;
            case 'SELECT':
                if (jQ(elem)[0].selectedIndex < 1) {
                    jQ(elem).focus();
                    Trigger_Error(elem, txt_error, scroll);
                    jQ("body").on("change", "#" + elemName, function () {
                        if (jQ('#' + elemName + '-error'))
                            jQ('#' + elemName + '-error').css({'visibility': 'hidden'});
                    });
                    valid = false;
                    return false;
                }
                break;
            case 'TEXTAREA':
                if ((jQ(elem).val().length == 0 || CMPS_hasHTMLTags(jQ(elem).val())) || CMPS_Trim(jQ(elem).val()).length == 0) {
                    jQ(elem).focus();
                    Trigger_Error(elem, txt_error, scroll);
                    valid = false;
                    return false;
                } else if (typeof(jQ(elem).attr("required_length")) !== typeof undefined && jQ(elem).attr("required_length") > jQ(elem).val().length) {
                    jQ(elem).focus();
                    Trigger_Error(elem, txt_error, scroll);
                    valid = false;
                    return false;
                }

                break;
        }

    });

    return valid;
}

function scholarshipsLogIn() {
    var form = jQuery('#signup_today_login').clone();

    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        LocationlankWindow(jQuery(form).find('input[name="view_external_link"]').val(), 'WindowReload');
    } else {
        setCookie('login_status', 1, {expires: 0, path: '/'});
        window.open(jQuery(form).find('input[name="view_external_link"]').val(), '_blank');
        window.location.reload();
    }

    jQ('[name="action"]', form).remove();

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: 'action=scholarships_log_in&' + jQ(form).serialize(),
        success: function (data) {
            window.sign_up_today_popup_obj.instance.popup.close();
        }
    });
}

function AnswersAndQuestionLogIn() {
    var login_form = jQ('#signup_today_login');
    var form_identification = jQ('input[name="identification"]', login_form).val();
    var l_id = jQ('input[name="listing_id"]', login_form).val();
    jQ('input[name="action"]', login_form).remove();

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: 'action=popup_log_in_log&form_name=' + form_identification + '&' + jQ(login_form).serialize(),
        success: function (data) {
        }
    });

    if(l_id) {
        if(form_identification == 'QuestionLogInPopUp'){
            setCookie('show-question-popup', l_id, {expires: 60 * 60 * 1, path: '/'});
        } else if(form_identification == 'AnswersLogIn') {
            setCookie('show-answer-popup', l_id, {expires: 60 * 60 * 1, path: '/'});
        } else if(form_identification == 'AnswerUpVoteLogIn') {
            setCookie('answer_up_vote', l_id, {expires: 60 * 60 * 1, path: '/'});
        } else if(form_identification == 'AnswerDownVoteLogIn') {
            setCookie('answer_down_vote', l_id, {expires: 60 * 60 * 1, path: '/'});
        } else if(form_identification == 'AnswerCommentLogIn') {
            setCookie('answer_comment', l_id, {expires: 60 * 60 * 1, path: '/'});
        } else if(form_identification == 'QuestionCommentLogIn') {
            setCookie('question_comment', l_id, {expires: 60 * 60 * 1, path: '/'});
        }  else if(form_identification == 'QuestionDetailAnswerLogIn') {
            setCookie('show_answer_form', l_id, {expires: 60 * 60 * 1, path: '/'});
        } else if(form_identification == 'QuestionFollowLogIn') {
            setCookie('question_follow_form', l_id, {expires: 60 * 60 * 1, path: '/'});
        } else if(form_identification == 'QuestionLogIn') {
            setCookie('add_question', JSON.stringify({'desc': jQ('#ask_question .message').val(), 'category': jQ('#question_category').val()}), {expires: 60 * 60 * 1, path: '/'});
        }
    }
}


function sendWelcomeEmailWithPassword() {
    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: 'action=send-welcome_email_with_password',
        success: function (data) {

        }
    });
}



function openBlankWindow() {
    // if( window.CodeAdapter.project_type === 'wordpress_plugin' && window.CodeAdapter.plugin_param === "universityparent_api=true" ){
    // 	window.BlankWindow = window.open('about:blank', '_blank');
    // } else {
    // 	window.OpenBlankWindowTimeout = setTimeout(function () {
    window.BlankWindow = window.open('about:blank', '_blank');
    // 	}, 400);
    // }
}

function closeBlankWindow() {
    clearTimeout(window.OpenBlankWindowTimeout);
    if (window.BlankWindow && window.BlankWindow != null) {
        window.BlankWindow.close();
    }
}

function LocationlankWindow(url, callback) {
    if (typeof(url) == 'undefined' || !url) {
        return false;
    }

    var waitOnBlankWindow = setInterval(function () {
        if (window.BlankWindow) {
            clearInterval(waitOnBlankWindow)
            window.BlankWindow.location = url;
            if (typeof(window[callback]) == 'function') {
                window[callback]();
            }
        }
    }, 100);
}

function WindowReload() {
    window.location.reload();
}

function fixPlaceholdersAdditional() {

    jQ('.current-location-input[placeholder], .textbook_search input[placeholder], .reply input[placeholder]:not(.input-phone), .reply textarea[placeholder]').focus(function () {
        var input = jQ(this);
        if (input.val() == input.attr('placeholder')) {
            input.val('');
            input.removeClass('placeholder');
        }
    }).blur(function () {
        var input = jQ(this);
        if (input.val() == '' || input.val() == input.attr('placeholder')) {
            input.addClass('placeholder');
            input.val(input.attr('placeholder'));
        }
    }).blur();

    jQ('.textbook_search form, form.reply, .location_main_search form').submit(function () {
        jQ(this).find('input[placeholder], textarea[placeholder]').each(function () {
            var input = jQ(this);
            if (input.val() == input.attr('placeholder')) {
                input.val('');
            }
        })
    });
}

function placeholderFocus(){
    var input = jQ(this);
    if (input.val() == input.attr('placeholder')) {
        input.val('');
        input.removeClass('placeholder');
    }
}

function placeholderBlur(){
    var input = jQ(this);
    if (input.val() == '' || input.val() == input.attr('placeholder')) {
        input.addClass('placeholder');
        input.val(input.attr('placeholder'));
    }
}

function fixPlaceholders(){
    jQ('[placeholder]').off('focus',placeholderFocus);
    jQ('[placeholder]').off('blur',placeholderBlur);
    jQ('[placeholder]').parents('form').off();

    jQ('[placeholder]').focus(placeholderFocus).blur(placeholderBlur).blur();

    jQ('body').on('submit','form',function() {
        jQ(this).find('[placeholder]').each(function() {
            var input = jQ(this);
            if (input.val() == input.attr('placeholder')) {
                input.val('');
            }
        })
    });
}

function initJsSocket() {
    if (typeof(SocketServerAddress) === 'undefined' || typeof(SocketServerPort) === 'undefined') {
        console.log('socket: connection data missing');
        return false;
    } else {
        window.SocketServer = io.connect('http://' + SocketServerAddress + ':' + SocketServerPort);

        // window.SocketServer.on('connecting', function () {
        // 	console.log('Socket connecting...');
        // })
        //
        // window.SocketServer.on('connect', function () {
        // 	console.log('Socket connected');
        // });

        window.SocketServer.on('message', function (data) {
            if (typeof(data.msg.callback_f) !== 'undefined' && typeof(window[data.msg.callback_f]) === 'function') {
                window[data.msg.callback_f].apply(undefined, data.msg.callback_fp);
            }
        });
    }
}

function SendToSocket(action, data) {
    console.log('SendToSocket');
    if (typeof(io) === 'function' && typeof(window.SocketServer) === 'object' && window.SocketServer != null) {
        console.log(data);
        window.SocketServer.emit("message", {"action": action, "msg": data});
    } else if (typeof(data.callback_f) !== 'undefined' && typeof(window[data.callback_f]) === 'function') {
        window[data.callback_f].apply(undefined, data.callback_fp);
    }
}


/******** START Question&Answers ********/
function RegisterQuestionAndAnswersEvents() {
    var answerActionBarIsFixed = false;
    var lastScrollTop = 0;
    var oldElemIntoViewClass = undefined;

    var back_to_top_status = false;

    MarkQuestionFollowButtonsOnPageLoad();

    jQ(window).scroll(function (event) {

        // back_to_top
        var scrollTop = jQ(window).scrollTop()
        if (scrollTop >= 300 && !back_to_top_status) {
            back_to_top_status = true;
            jQ('#scroll_top').fadeIn(300);
        } else if (scrollTop < 300 && back_to_top_status) {
            back_to_top_status = false;
            jQ('#scroll_top').fadeOut(300);
        }

        var elemIntoView = undefined;

        jQ(".post.answer").each(function (i, elem) {
            var currentElem = jQ(elem);
            var currentActionBarElem = jQ('.action-buttons-main:not(.fixed)', currentElem);
            if (typeof(currentActionBarElem) != 'undefined' && jQ(currentActionBarElem).length) {
                var isIntoViewActionBar = isScrolledIntoView(currentActionBarElem);
                if (isIntoViewActionBar) {
                    elemIntoView = undefined;
                    return false;
                }
            }

            var isIntoView = isScrolledIntoView(currentElem, false);
            var isIntoView = isScrolledIntoView(currentElem, false);
            if (isIntoView) {
                elemIntoView = currentElem;
            }

        });

        if (typeof(elemIntoView) != 'undefined') {
            var elemIntoViewClass = jQ(elemIntoView).attr("class").match(/answer_[\w-]*\b/)[0];
            if (oldElemIntoViewClass != elemIntoViewClass) {
                oldElemIntoViewClass = elemIntoViewClass;
                jQ(".post.answer .action-buttons-main").removeClass('fixed');
                jQ('.action-buttons-main', elemIntoView).addClass('fixed');
            }
        } else {
            oldElemIntoViewClass = undefined;
            jQ(".post.answer .action-buttons-main").removeClass('fixed');
        }
    });

    jQ('body').on('click', '.question_info .dots_link, .answer .dots_link', function () {
        var elem = jQ(this);
        if (!jQ(elem).hasClass('shown')) {
            jQ(elem).parent().find('.qa-author-menu').fadeIn(100, function () {
                jQ(elem).addClass('shown');
            });
        } else {
            jQ(elem).parent().find('.qa-author-menu').fadeOut(100, function () {
                jQ(elem).removeClass('shown');
            });
        }
    });

}

function ShowQuestionDetailAnswerForm(elem) {
    var parent = jQ(elem).closest(".top_reply, .bottom_reply")
    jQ(".sceditor_block", parent).slideToggle(300);
    return false;
}


function MarkQuestionFollowButtonsOnPageLoad() {
    if (typeof CrossStorage === 'undefined') {
        return false;
    }
    CrossStorage.get('question_follows', function (data) {

        var questionFollowArray = data;
        try {
            questionFollowArray = JSON.parse(questionFollowArray);
        } catch (e) {
            questionFollowArray = [];
        }

        if (questionFollowArray) {
            for (var key in questionFollowArray) {
                var questionFollow = questionFollowArray[key];
                jQ('.follow-btn .text', jQ('.' + questionFollow.container)).html('Unfollow');
                jQ('.follow-btn', jQ('.' + questionFollow.container)).addClass('followed');
            }
        }

        jQ('.question-buttons-bar .follow-btn, .action-buttons-bar .follow-btn').removeClass('disabled');

    });
}

function MarkAnswerVoteButtonsOnPageLoad() {

    // if(!jQ('.questions_and_answers_detail_view').length)
    // 	return;

    if (typeof CrossStorage !== 'undefined') {
        CrossStorage.get('answer_votes', function (data) {

            var answerVotesArray = data;
            try {
                answerVotesArray = JSON.parse(answerVotesArray);
            } catch (e) {
                answerVotesArray = [];
            }

            if (answerVotesArray) {
                for (var key in answerVotesArray) {
                    var answerVote = answerVotesArray[key];
                    if (answerVote.type == 'up') {
                        ;
                        jQ('.upvote-btn' + ' .text', jQ('.' + answerVote.container)).html('Upvoted');
                        jQ('.upvote-btn', jQ('.' + answerVote.container)).addClass('voted');
                        // jQ('.upvote-btn', jQ('.' + answerVote.container)).removeClass('disabled');
                    } else if (answerVote.type == 'down') {
                        jQ('.downvote-btn' + ' .text', jQ('.' + answerVote.container)).html('Downvoted');
                        jQ('.downvote-btn', jQ('.' + answerVote.container)).addClass('voted');
                        // jQ('.downvote-btn', jQ('.' + answerVote.container)).removeClass('disabled');
                    }
                }
            }

            jQ('.upvote-btn').removeClass('disabled');
            jQ('.downvote-btn').removeClass('disabled');

        });
    }

}

function AnswerUpVote(elem, event, code, qcode) {
    var event = event || window.event;
    if (typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if (jQ(elem).hasClass('disabled')) {
        return;
    }

    AnswerVoteAction('.upvote-btn', elem, code, qcode);
}

function ProfileFollow(elem, event, user_id) {
    var event = event || window.event;
    if (typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if (jQ(elem).hasClass('disabled')) {
        return;
    }

    ProfileFollowAction('.follow-btn', elem, user_id);
}

function AnswerDownVote(elem, event, code, qcode) {
    var event = event || window.event;
    if (typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if (jQ(elem).hasClass('disabled')) {
        return;
    }

    AnswerVoteAction('.downvote-btn', elem, code, qcode);
}

function QuestionFollow(elem, event, qcode) {
    var event = event || window.event;
    if (typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if (jQ(elem).hasClass('disabled')) {
        return;
    }

    QuestionFollowAction(elem, qcode);
}

function QAAddComment(elem, event, c_id){
    var event = event || window.event;
    if(typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if(c_id){
        var container = jQ(elem).parents('.answer_comment[main_id="'+c_id+'"]');
    }
    else{
        var container = jQ(elem).parents('.post.answer').length ? jQ(elem).parents('.post.answer') : jQ(elem).parents('.question_row');
    }

    var form =  jQ('form[name="answer_comment"]', container).eq(0);
    var acode = jQ('input[name="a_id"]', form).val();
    var qcode = jQ('input[name="q_id"]', form).val();
    var ccode = jQ('input[name="parent_c_id"]', form).val();

    var sceditor_instance = jQ(form).find('textarea:first').sceditor('instance');
    var message = sceditor_instance.getWysiwygEditorValue();
    if(message.indexOf(sceditor_instance.opts.placeholder+'</span></p>') != -1){
        message = '';
    }

    jQ(form).find('textarea:first').val(message);

    var validate = Validate_Data(jQ('input, select, checkbox',form), false);

    if(!validate || !message){
        return false;
    }

    jQ('.sm_loader', container).fadeIn(200);

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'qa_add_comment=1&' + jQ(form).serialize(),
        type: "POST",
        dataType: "json",
        success: function (data) {
            jQ('.sm_loader', container).fadeOut(300);

            if(typeof(data) !== 'undefined' && data.success){
                jQ('textarea', form).val("");
                sceditor_instance.setWysiwygEditorValue("<p></p>");

                jQ(".reply_editor").hide();
                jQ(".action-buttons-bar").show();

                if (c_id) {
                    SendToSocket('qa_comment', {
                        'callback_f': 'QAChildComment',
                        'callback_fp': [null, acode, qcode, ccode, true, 1]
                    });
                }
                else {
                    SendToSocket('qa_comment', {
                        'callback_f': 'QAComment',
                        'callback_fp': [null, acode, qcode, true, 1]
                    });
                    SendToSocket('qa_comment_count', {
                        'callback_f': 'QASetCommentsCount',
                        'callback_fp': [acode, qcode, (parseInt(jQ('.comments-btn', container).attr('data')) + 1)]
                    });
                }

            }
        }
    });
}

function QASetCommentsCount(acode, qcode, sum){
    if (acode) {
        var elem = jQ('.answer_' + acode + ' .action-buttons-main .comments-btn');
    } else {
        var elem = jQ('.question_' + qcode + ' .comments-btn');
    }

    if(typeof elem !== 'undefined' && elem.length){
        jQ(elem).attr('data', sum);
        var count_elem = jQ('.count', elem);
        var text_elem = jQ('.text', elem);

        if(!sum){
            jQ(count_elem).remove();
        } else if(typeof count_elem !== 'undefined' && count_elem.length){
            jQ(count_elem).html(sum);
        } else {
            jQ(elem).append('<span class="count">' + sum + '</span>');
        }

        if(sum > 1 && jQ(text_elem).html() !== 'Comments'){
            jQ(text_elem).html('Comments');
        } else if(sum <= 1 && jQ(text_elem).html() !== 'Comment'){
            jQ(text_elem).html('Comment');
        }
    }
}

function QAComment(event, acode, qcode, update_flag, page){
    var event = event || window.event;
    if(typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if (acode) {
        var container = jQ('.answer_' + acode);
    } else {
        var container = jQ('.question_' + qcode);
    }

    var update_flag = update_flag || false;

    if(typeof container !== 'undefined' && container.length)
    {
        var comment_container = jQ('.comment_container:first', container);

        if(update_flag && !jQ(comment_container).hasClass('opened')){
            return false;
        } else if(!update_flag && !scrollToAminate && jQ('.action-buttons-main.fixed',container).length){
            jQ('html, body').stop().animate({
                scrollTop: jQ('.scrollto', container).offset().top
            }, 200, 'swing', function(){scrollToAminate = false;});
        }

        var slide_spead = 500;
        var new_page = page || 1;
        var current_page = jQ(comment_container).attr('page') || 1;


        if (update_flag || jQ(comment_container).hasClass('update')) {
            if (jQ(container).hasClass('processing')) {
                return false;
            }

            jQ(container).addClass('processing');

            slide_spead = 200;
            if (!jQ('.comment_inner', container).length) {
                jQ(comment_container).html('<div class="comment_inner"><div class="loader"></div></div>');
            } else if(new_page == current_page) {
                jQ('.page-content-1:first', container).prepend('<div class="tmp_loader remove"></div>');
            }

            if(new_page > 1){
                jQ('.load-more:first', comment_container).remove();
                jQ('.comment_inner:first', comment_container).append('<div class="loader remove"></div>');
            }

            window.CodeAdapter.ajaxRequest({
                url: "ajaxapi.php",
                data: 'action=get_qa_comments&a_id=' + acode + '&q_id=' + qcode + '&page=' + new_page  + '&cpage=' + current_page + '&update=' + (update_flag ? 1 : 0),
                type: "POST",
                dataType: "html",
                success: function (data) {
                    if(jQ('.page-content-'+new_page, comment_container).length){
                        jQ('.page-content-'+new_page, comment_container).replaceWith(data);
                    }
                    else if(new_page > 1){
                        jQ('.loader', comment_container).replaceWith(data);
                    } else {
                        jQ(comment_container).html(data);
                    }

                    jQ(comment_container).find('textarea.valid').sceditor({
                        toolbar: "",
                        style: "/css/jquery.sceditor.default.css",
                        placeholder: "Add a comment..."
                    });
                    jQ(comment_container).find('textarea.reply_textarea').sceditor({
                        toolbar: "",
                        style: "/css/jquery.sceditor.default.css",
                        placeholder: "Reply..."
                    });
                    jQ(comment_container).find('textarea.edit_comment_textarea').sceditor({
                        toolbar: "",
                        style: "/css/jquery.sceditor.default.css",
                        placeholder: ""
                    });
                    jQ(comment_container).find('textarea.valid').sceditor('instance').height(28);
                    jQ(comment_container).find('textarea.valid').sceditor('instance').css('body { padding:5px 9px;color:#666; } p{ color:#666; }');

                    jQ(comment_container).find('textarea.reply_textarea,  textarea.edit_comment_textarea').each(function(){
                        jQ(this).sceditor('instance').height(28);
                        jQ(this).sceditor('instance').css('body { padding:5px 9px;color:#666; } p{ color:#666; }');

                    });

                    MarkCommentVoteButtonsOnPageLoad();


                    jQ(comment_container).removeClass('update');
                    jQ('.remove', container).remove();


                    if(current_page < new_page){
                        jQ(comment_container).attr('page', new_page);
                    }

                    jQ(container).removeClass('processing');

                    if(current_page > new_page){
                        QAComment(event, acode, qcode, true, new_page+1)
                    }
                }
            });
        }

        if (jQ(comment_container).hasClass('closed')) {
            jQ(comment_container).slideDown(slide_spead).removeClass('closed').addClass('opened');
        } else if (jQ(comment_container).hasClass('opened') && !update_flag) {
            jQ(comment_container).slideUp(slide_spead).removeClass('opened').addClass('closed');
        }
    }
}

function AnswerVoteAction(btn_selector, elem, code, qcode) {
    var votes_number = ~~jQ(elem).parents('.post').attr('data');
    var action = '';

    jQ('.count', elem).html('<div class="loader"></div>');

    switch (btn_selector) {
        case '.upvote-btn':
            if (jQ(elem).hasClass('voted')) {
                action = 'downvote';
            } else {
                action = 'upvote';
            }
            break;
        case '.downvote-btn':
            if (jQ(elem).hasClass('voted')) {
                action = 'upvote';
            } else {
                action = 'downvote';
            }
            break;
    }

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'answer_vote_action=1&action=' + action + '&a_id=' + code + '&q_id=' + qcode + '&votes=' + votes_number,
        type: "POST",
        dataType: "json",
        success: function (data) {
            if (typeof data !== 'undefined') {
                if (data.success) {
                    SendToSocket('qa_vote', {
                        'callback_f': 'AnswerSetVote',
                        'callback_fp': ['answer_' + code, btn_selector, data.vote, data.vote_sum, data.vote_formatted]
                    });

                    AnswerMarkerVoted(jQ('.answer_' + code), btn_selector, data.vote);
                } else {
                    if (votes_number) {
                        jQ('.count', elem).html(votes_number);
                    } else {
                        jQ('.count', elem).remove();
                    }

                    AnswerMarkerVoted(jQ('.answer_' + code), btn_selector, data.vote);
                }

            }
        }
    });
}

function ProfileFollowAction(btn_selector, elem, user_id) {

    var action = '';

    jQ('.count', elem).html('<div class="loader"></div>');
    jQ('.count', elem).show();

    switch (btn_selector) {
        case '.follow-btn':
            if (jQ(elem).hasClass('voted')) {
                action = 'unfollow';
            } else {
                action = 'follow';
            }
            break;
    }

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'profile_follow_action=1&action=' + action + '&user_id=' + user_id,
        type: "POST",
        dataType: "json",
        success: function (data) {
            //SendToSocket('qa_vote', {'callback_f': 'AnswerSetVote', 'callback_fp':['user_id_' + user_id, btn_selector, data.vote, data.vote_sum, data.vote_formatted]});
            if (typeof data !== 'undefined' && data.status) {
                FollowMarkerVoted(jQ('.follow_' + user_id), btn_selector, data.followers_count, data.type);
            } else {
                jQ('.count', elem).html(jQ(elem).attr('followers'));
                if (jQ(elem).attr('followers') > 0) {
                    jQ('.count', elem).show();
                } else {
                    jQ('.count', elem).hide();
                }
            }
        },
        error: function () {
            jQ('.count', elem).html(jQ(elem).attr('followers'));
            if (jQ(elem).attr('followers') > 0) {
                jQ('.count', elem).show();
            } else {
                jQ('.count', elem).hide();
            }
        }
    });
}

function FollowMarkerVoted(container, btn_selector, followers_count, type) {
    switch (btn_selector) {
        case '.follow-btn':

            if (followers_count > 0) {
                jQ(btn_selector + ' .count', container).show();
            } else {
                jQ(btn_selector + ' .count', container).hide();
            }

            if (type == 'unfollow') {
                jQ(btn_selector + ' .text', container).html('Follow');
                jQ(btn_selector, container).removeClass('followed');
                jQ(btn_selector + ' .count', container).html(followers_count);
                jQ(btn_selector, container).attr('followers', followers_count)
            } else if (type == 'follow') {
                jQ(btn_selector + ' .text', container).html('Unfollow');
                jQ(btn_selector, container).addClass('followed');
                jQ(btn_selector + ' .count', container).html(followers_count)
                jQ(btn_selector, container).attr('followers', followers_count)
            }

            break;

    }
}

function QuestionFollowAction(elem, qcode) {
    var follow_number = ~~jQ(elem).attr('data');
    var follow_title = jQ('h2', jQ(elem).parents('.post')).text();
    if (!follow_title.length) {
        follow_title = jQ('h1.listing_title').text();
    }
    var action = '';

    if (jQ('.count', elem).length) {
        jQ('.count', elem).html('<div class="loader"></div>');
    } else {
        jQ(elem).append('<span class="count"><div class="loader"></div></span>');
    }

    if (jQ(elem).hasClass('followed')) {
        action = 'unfollow';
    } else {
        action = 'follow';
    }

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'question_follow_action=1&action=' + action + '&q_id=' + qcode + '&follows=' + follow_number,
        type: "POST",
        dataType: "json",
        success: function (data) {
            if (typeof data !== 'undefined') {
                if (data.success) {
                    SendToSocket('qa_q_follow', {
                        'callback_f': 'QuestionSetFollow',
                        'callback_fp': ['question_' + qcode, data.follow_sum, data.follow_formatted]
                    });

                    QuestionMarkerFollowed('question_' + qcode, data.status);

                    if (data.status == 'followed') {
                        jQ('#send_confirm_msg_info div').html('<h2>Success, you are now following the question "' + follow_title + '" You will receive email updates when people respond to this question.</h2>');
                        ShowPopup('send_confirm_msg_info');
                    }
                } else {
                    if (follow_number) {
                        jQ('.count', elem).html(follow_number);
                    } else {
                        jQ('.count', elem).remove();
                    }

                    QuestionMarkerFollowed('question_' + qcode, data.status);
                }

            }
        }
    });
}

function AnswerMarkerVoted(container, btn_selector, vote) {
    switch (btn_selector) {
        case '.upvote-btn':
            if (jQ(btn_selector, container).hasClass('voted')) {
                jQ(btn_selector + ' .text', container).html('Upvote');
                jQ(btn_selector, container).removeClass('voted');

                unmarkAnswerVote(jQ(container).attr("class").match(/\b(answer_(.*))\b/)[1], 'up');
            } else if (vote != 0) {
                jQ(btn_selector + ' .text', container).html('Upvoted');
                jQ(btn_selector, container).addClass('voted');

                markAnswerVote(jQ(container).attr("class").match(/\b(answer_(.*))\b/)[1], 'up');
            }

            if (jQ('.downvote-btn', container).hasClass('voted')) {
                jQ('.downvote-btn .text', container).html('Downvote');
                jQ('.downvote-btn', container).removeClass('voted');
                // unmarkAnswerVote(jQ(container).attr("class").match(/\b(answer_(.*))\b/)[1], 'down');
            }
            break;
        case '.downvote-btn':
            if (jQ(btn_selector, container).hasClass('voted')) {
                jQ(btn_selector + ' .text', container).html('Downvote');
                jQ(btn_selector, container).removeClass('voted');
                unmarkAnswerVote(jQ(container).attr("class").match(/\b(answer_(.*))\b/)[1], 'down');
            } else if (vote != 0) {
                jQ(btn_selector + ' .text', container).html('Downvoted');
                jQ(btn_selector, container).addClass('voted');
                markAnswerVote(jQ(container).attr("class").match(/\b(answer_(.*))\b/)[1], 'down');
            }

            if (jQ('.upvote-btn', container).hasClass('voted')) {
                jQ('.upvote-btn .text', container).html('Upvote');
                jQ('.upvote-btn', container).removeClass('voted');
                // unmarkAnswerVote(jQ(container).attr("class").match(/\b(answer_(.*))\b/)[1], 'up');
            }
            break;
    }
}

function QuestionMarkerFollowed(container, status){
    var elem = jQ('.'+container).find('.follow-btn');
    if(status == 'follow'){
        jQ('.text', elem).html('Follow');
        jQ(elem).removeClass('followed');
    } else if(status == 'followed'){
        jQ('.text', elem).html('Unfollow');
        jQ(elem).addClass('followed');
    }

    CrossStorage.get('question_follows', function (data) {
        var questionFollowArray = data;
        try {
            questionFollowArray = JSON.parse(questionFollowArray);
        } catch (e) {
            questionFollowArray = [];
        }
        if (!questionFollowArray) {
            questionFollowArray = [];
        }

        for (var key in questionFollowArray) {
            var questionVote = questionFollowArray[key];
            if (questionVote.container == container) {
                questionFollowArray.splice(key, 1);
            }
        }

        if(status != 'follow') {
            var questionVote = {'container':container};
            questionFollowArray.push( questionVote );
        }

        if (!questionFollowArray) {
            questionFollowArray = [];
        }

        // console.log('-----');
        // console.log(questionFollowArray);
        // console.log(JSON.stringify(answerVotesArray));

        CrossStorage.set({
            'question_follows': JSON.stringify(questionFollowArray)
        });

    });
}

function QuestionSetFollow(btn_selector, sum, value) {
    var elem = jQ('.' + btn_selector).find('.follow-btn');

    if (typeof elem !== 'undefined' && elem.length) {
        jQ(elem).attr('data', sum);

        if (jQ('.count', elem).length) {
            if (sum == 0) {
                jQ('.count', elem).remove();
            } else {
                jQ('.count', elem).html(value);
            }
        } else if (value != 0) {
            jQ(elem).append('<span class="count">' + value + '</span>');
        }
    }
}

function AnswerSetVote(elem_id, btn_selector, vote, sum, value) {
    var elem = jQ('.' + elem_id);

    if (typeof elem !== 'undefined' && elem.length) {
        jQ(elem).attr('data', sum);

        if (jQ('.upvote-btn .count', elem).length) {
            if (sum == 0) {
                jQ('.upvote-btn .count', elem).remove();
            } else {
                jQ('.upvote-btn .count', elem).html(value);
            }
        } else if (value != 0) {
            jQ('.upvote-btn', elem).append('<span class="count">' + value + '</span>');
        }
    }
}

function isAnswerInStorage(answerVotesArray, container, type) {

    if (!answerVotesArray) {
        return false;
    }

    for (var key in answerVotesArray) {
        var answerVote = answerVotesArray[key];
        if (answerVote.container == container && answerVote.type == type) {
            return true;
        }
    }
    return false;
}

function markAnswerVote(container, type) {
    CrossStorage.get('answer_votes', function (data) {
        var answerVotesArray = data;
        try {
            answerVotesArray = JSON.parse(answerVotesArray);
        } catch (e) {
            answerVotesArray = [];
        }

        if (!answerVotesArray) {
            answerVotesArray = [];
        }

        if (!isAnswerInStorage(answerVotesArray, container, type)) {
            var answerVote = {'container': container, 'type': type};
            answerVotesArray.push(answerVote);
        }

        var unmark_type = 'down';
        if (type == 'down') {
            unmark_type = 'up';
        }

        for (var key in answerVotesArray) {
            var answerVote = answerVotesArray[key];
            if (answerVote.container == container && answerVote.type == unmark_type) {

                answerVotesArray.splice(key, 1);
            }
        }

        CrossStorage.set({
            'answer_votes': JSON.stringify(answerVotesArray)
        });

        console.log(JSON.stringify(answerVotesArray));

    });
}

function unmarkAnswerVote(container, type) {

    CrossStorage.get('answer_votes', function (data) {
        var answerVotesArray = data;
        try {
            answerVotesArray = JSON.parse(answerVotesArray);
        } catch (e) {
            answerVotesArray = [];
        }
        if (!answerVotesArray) {
            answerVotesArray = [];
        }

        for (var key in answerVotesArray) {
            var answerVote = answerVotesArray[key];
            if (answerVote.container == container && answerVote.type == type) {

                answerVotesArray.splice(key, 1);
            }
        }

        if (!answerVotesArray) {
            answerVotesArray = [];
        }

        // console.log(answerVotesArray);
        // console.log(JSON.stringify(answerVotesArray));

        CrossStorage.set({
            'answer_votes': JSON.stringify(answerVotesArray)
        });

    });
}


function showFullAnswer(link) {

    var short_answer = jQ(link).closest('.short_answer');
    var full_answer = jQ(link).closest('.listing_short_description').find('.full_answer');
    jQ(short_answer).hide();
    jQ(full_answer).show();

    console.log(short_answer);
    console.log(full_answer);

    return false;
}

function SCEditor_AddPhoto() {

    window.isAnswerPopupOpen = false;
    window.isEditQuestionPopupOpen = false;

    if (jQ("#add_answer_popup").css("display") != "none") {
        window.isAnswerPopupOpen = true;
    }

    if (jQ("#admin_edit_questions").css("display") != "none") {
        window.isEditQuestionPopupOpen = true;
    }

    jQ("#campus-image-upload .column:last form").attr("onsubmit", "sceditor_DownloadPhoto(document.getElementById('campus-image-upload').imageIndex);return false;");
    jQ("#campus-image-upload .column:last .btn-blue").attr("onclick", "sceditor_DownloadPhoto(document.getElementById('campus-image-upload').imageIndex);return false;");

    initSwfUploadForSCEditor();

    popup({
        popup_id: "campus-image-upload"
    });

}

function initSwfUploadForSCEditor() {

    jQ('#campus-image-upload input.grey_submit').hide();

    if (Campus_IsHTML5UploadAllowed()) {
        var html = '<form name="fileupload" id="fileupload" class="btn btn-blue"><span>Select and Upload</span><input type="file" name="Filedata"  accept="image/*;capture=camera" onchange="sceditor_UploadImageHTML5(this);"></form>';
        $('campus-posting-upload-image-button').innerHTML = html;

    } else if (SCEditorGlobalUploadSWF == null) {
        var settings = {
            flash_url: CampusPostingRootURL + "js/swfupload.swf",
            upload_url: CampusPostingRootURL + "upload-image.php",
            file_size_limit: "2000 KB",
            file_types: "*.jpg;*.jpeg;*.gif;*.png",
            file_types_description: "Images",
            file_upload_limit: 0,
            file_queue_limit: 1,
            custom_settings: {
                progressTarget: "campus-posting-upload-image-progress-bar"
            },

            prevent_swf_caching: true,
            debug: false,

            // Button settings
            button_image_url: window.imagesDomain + "/img/button/continue_button3.png",
            button_width: "153",
            button_height: "38",
            button_placeholder_id: "campus-posting-upload-image-button",
            button_text: '<font size="16" color="#FFFFFF">Select and Upload</font>',
            button_text_style: '',
            button_text_left_padding: 9,
            button_text_top_padding: 8,

            file_dialog_complete_handler: Campus_fileDialogComplete,

            upload_success_handler: sceditor_UploadImageSuccess,
            upload_progress_handler: Campus_UploadProgress,
            upload_complete_handler: Campus_UploadComplete,
            upload_error_handler: Campus_UploadImageError,
            file_queue_error_handler: Campus_UploadImageQueueError
        };

        SCEditorGlobalUploadSWF = new SWFUpload(settings);

    }

    if (document.getElementById("campus-posting-upload-image-progress-bar"))
        document.getElementById("campus-posting-upload-image-progress-bar").style.width = '1px';
}


function Campus_IsHTML5UploadAllowed() {
    if (!window.CodeAdapter.html5ImageUploadIsAllowed()) {
        return false;
    }

    if (window.FormData !== undefined) {

        if (/OPR[\/\s](\d+\.\d+)/.test(navigator.userAgent)) { //test for Opera/x.x
            var opversion = new Number(RegExp.$1) // capture x.x portion and store as a number
            if (opversion <= 12.14)
                return false;
        }
        if (/Firefox[\/\s](\d+\.\d+)/.test(navigator.userAgent)) { //test for Firefox/x.x or Firefox x.x (ignoring remaining digits);
            var ffversion = new Number(RegExp.$1) // capture x.x portion and store as a number
            if (ffversion <= 6)
                return false;
        }

        if (/Safari[\/\s](\d+\.\d+)/.test(navigator.userAgent)) { //test for Safari/x.x
            var sfversion = new Number(RegExp.$1) // capture x.x portion and store as a number
            if (sfversion <= 5.2)
                return false;
        }
        return true;
    }
    return false;
}

function Campus_UploadImageError(err) {
    if (typeof(err) === 'undefined') {
        var message = 'All images must be in .png, .gif or .jpg format, contact <a href="mailto:' + EmailAddr + '" target="_blank">' + EmailAddr + '</a> if you need any help';
    } else {
        var message = err;
    }

    //document.getElementById('error_upload_posting_img').style.color = 'orange';
    jQ('#campus-posting-download-image-loader').css('display', 'none');
    CMPS_blink(CMPS_setHTML(document.getElementById('error_upload_posting_img'), message));

}
function Campus_UploadImageQueueError(err) {
    var message = 'All images must be in .png, .gif or .jpg format, contact  <a href="mailto:' + EmailAddr + '" target="_blank">' + EmailAddr + '</a> if you need any help';
    //document.getElementById('error_upload_posting_img').style.color = 'red';
    jQ('#campus-posting-download-image-loader').css('display', 'none');
    CMPS_blink(CMPS_setHTML(document.getElementById('error_upload_posting_img'), message));

}

function Campus_UploadComplete(fileObj) {

}

function Campus_UploadProgress(file, bytesLoaded, bytesTotal) {
    try {
        var percent = Math.ceil((bytesLoaded / bytesTotal) * 100);

        if ($(this.customSettings.progressTarget)) {
            var pp = $(this.customSettings.progressTarget);
            pp.style.width = (percent + 1) + '%';
            pp.style.visibility = 'visible';
        }

    } catch (ex) {
        this.debug(ex);
    }
}

function Campus_fileDialogComplete(numFilesSelected, numFilesQueued) {

    //show image download preloader
    jQ('#campus-posting-download-image-loader').css('display', 'block');

    try {
        if (numFilesSelected > 0)
            this.startUpload();
    } catch (ex) {
        this.debug(ex);
    }
}

function Campus_Profile_fileDialogComplete(numFilesSelected, numFilesQueued) {

    //show image download preloader
    jQ('#profile-download-photo-loader').css('display', 'block');

    try {
        if (numFilesSelected > 0)
            this.startUpload();
    } catch (ex) {
        this.debug(ex);
    }
}

function sceditor_UploadImageSuccess(file, server_data) {
    //hide image download preloader
    jQ('#campus-posting-download-image-loader').css('display', 'none');
    var server_data = JSON.parse(server_data);
    var ar = server_data.res.split('\|');
    var bigImage = ar[0];
    var smallImage = ar[1];

    sceditor_DownloadPhotoCallback(1, smallImage, bigImage);
}

function sceditor_UploadImageHTML5(fileInput) {
    var file = fileInput.files[0];
    if (file)
        Campus_UploadImageUpload(file, sceditor_UploadImageHTML5Success, Campus_UploadImageHTML5Error, Campus_UploadImageHTML5Progress);
}

function Campus_UploadImageHTML5Progress() {
    //preloader here
    jQ('#error_upload_posting_img').html('');
    jQ('#campus-posting-download-image-loader').css("display", "block");
}

function Campus_UploadImageHTML5Error() {
    alert('Error upload file');
}

function Campus_UploadImageUpload(file, onSuccess, onError, onProgress) {

    var xhr = new XMLHttpRequest();
    xhr.onload = xhr.onerror = function (param) {
        if (this.status != 200 && xhr.responseText.length == 0) {
            onError(this);
            return;
        }
        onSuccess(xhr.responseText);
    };


    xhr.upload.onprogress = function (event) {
        onProgress(event.loaded, event.total);
    }

    var oMyForm = new FormData();
    var index = document.getElementById('campus-image-upload').imageIndex;
    oMyForm.append("campus-image-index", index);


    oMyForm.append("Filedata", file);


    xhr.open("POST", CampusPostingRootURL + "upload-image.php");
    xhr.send(oMyForm);



}

function sceditor_UploadImageHTML5Success(response) {
    var response = JSON.parse(response);
    if (response.error == true) {
        Campus_UploadImageError();
    }
    else {
        var ar = response.res.split('\|');
        var bigImage = ar[0];
        var smallImage = ar[1];

        sceditor_DownloadPhotoCallback(1, smallImage, bigImage);
    }

}


function sceditor_DownloadPhotoCallback(status, smallImage, bigImage) {
    $('campus-posting-download-image-loader').style.display = 'none';
    $('campus-posting-download-image-url').value = 'http://';
    if (status > 0) {

        var value = window.activeSCEditor.getWysiwygEditorValue().toLowerCase();

        if (value.indexOf(window.activeSCEditor.opts.placeholder.toLowerCase() + "</span></p>") != -1) {
            window.activeSCEditor.setWysiwygEditorValue("<p></p>");
        }

        window.activeSCEditor.wysiwygEditorInsertHtml(
            '<img style="max-width:630px;" src="' + bigImage + '" />'
        );
        closeImageUploadPopup();

        if (window.isAnswerPopupOpen) {
            ShowPopup('add_answer_popup');
        }

        if (window.isEditQuestionPopupOpen) {
            ShowPopup('admin_edit_questions');
        }

    }
}

function closeImageUploadPopup() {
    var popup = document.getElementById('campus-image-upload');
    if (popup)
        popup.style.display = 'none';
    jQ('.popup_overlay, #campus-posting-download-image-loader').hide();
    jQ('#campus-image-upload-error').text('Note: must be in .png, .gif or .jpg format').css('color', '');
}

function sceditor_DownloadPhoto(index) {
    var url = $('campus-posting-download-image-url').value;
    if (url != null && url.length) {
        //send AJAX request
        $('campus-posting-download-image-loader').style.display = 'block';
        var unique = Math.round(Math.random() * 1000);
        var urlReq = AJAXBaseURL + '/ajaxapi.php?sceditor=1&download_external_image=1' + AJAXSessionParam + '&url=' + escape(url) + '&index=' + index + '&unique=' + unique;
        dhtmlCampusAveLoadScript(urlReq);
    }
}


function removeProfilePhoto() {

    jQ('.sceditor_block .current_user_photo').attr('src', '/img/no-user-img.jpg');
    jQ('.sceditor_block .add_profile_image').show();

    closeProfilePhotoUploadDialog();
    saveUserPhoto('');

    jQ('.author_info #current_user_photo').attr('src','/img/no_image_profile2.jpg');

    if (window.isAnswerPopupOpen) {
        ShowPopup('add_answer_popup');
    }
}

/******** End SCEditor ********/

/******** Start Profile image upload ********/

function Profile_Photo_InitSwfUpload() {
    document.getElementById('profile-photo-upload-error').style.color = '#333333';

    if (Campus_IsHTML5UploadAllowed()) {
        var html = '<form name="fileupload" id="fileupload" class="btn btn-blue"><span>Select and Upload</span><input type="file" name="Filedata"  accept="image/*;capture=camera" onchange="Campus_Profile_UploadImageHTML5(this);"></form>';
        $('profile-upload-photo-button').innerHTML = html;

    }
    else if (GlobalUploadSWF == null) {
        var settings = {
            flash_url: CampusPostingRootURL + "js/swfupload.swf",
            upload_url: CampusPostingRootURL + "upload-image.php",
            file_size_limit: "500 KB",
            file_types: "*.jpg;*.jpeg;*.gif",
            file_types_description: "Images",
            file_upload_limit: 0,
            file_queue_limit: 1,
            custom_settings: {
                progressTarget: "profile-upload-photo-progress-bar"
            },

            prevent_swf_caching: true,
            debug: false,

            // Button settings
            button_image_url: window.imagesDomain + "/img/button/continue_button3.png",
            button_width: "153",
            button_height: "38",
            button_placeholder_id: "profile-upload-photo-button",
            button_text: '<font size="16" color="#FFFFFF" face="Helvetica">Select and Upload</font>',
            button_text_style: '',
            button_text_left_padding: 9,
            button_text_top_padding: 8,

            file_dialog_complete_handler: Campus_Profile_fileDialogComplete,

            upload_success_handler: Profile_UploadPhotoSuccess
        };


        GlobalUploadSWF = new SWFUpload(settings);


    }

    if (document.getElementById("profile-upload-photo-progress-bar"))
        document.getElementById("profile-upload-photo-progress-bar").style.width = '1px';

}

function Profile_UploadPhotoSuccess(file, server_data) {

    document.getElementById('profile-photo-upload-error').style.color = 'black';
    CMPS_setHTML(document.getElementById('profile-photo-upload-error'), 'Note: must be in .png, .gif or .jpg format');


    var server_data = JSON.parse(server_data);
    var ar = server_data.res.split('\|');
    var bigImage = ar[0];
    var smallImage = ar[1];
    Profile_AddPhotoCallback(smallImage, bigImage)

}

function Profile_AddPhotoCallback(smallImage, bigImage) {

    if (jQ('.questions_and_answers_channel_view_page,.questions_and_answers_detail_view').length) { //Questions & Answers page
        window.CodeAdapter.ajaxRequest({
            url: "ajaxapi.php",
            data: "action=get_user_questions",
            dataType: "json",
            type: "POST",
            cache: "false",
            success: function (data) {

                if (data.answers) {
                    var size = data.answers.length;
                    var i = 0;
                    for (i = 0; i < size; i++) {
                        if (jQ('.answer[main_id="' + data.answers[i] + '"]').length) {
                            jQ('.answer[main_id="' + data.answers[i] + '"] .post_image img').attr('src', smallImage);
                        }
                    }
                }

                if (data.questions) {
                    var size = data.questions.length;
                    var i = 0;
                    for (i = 0; i < size; i++) {
                        if (jQ('.question_row[main_id="' + data.questions[i] + '"]').length) {
                            jQ('.question_row[main_id="' + data.questions[i] + '"] .post_image img').attr('src', smallImage);
                        }
                        if (jQ('h1.listing_title .hotlist[listing_id="' + data.questions[i] + '"]').length) {
                            jQ('.author_image_inner img').attr('src', smallImage);
                        }
                    }
                }

            }
        });
        jQ('.sceditor_block .current_user_photo').attr('src', smallImage);
        jQ('.sceditor_block .add_profile_image').hide();
    }
    else { //Profile page
        var photo = document.getElementById('current_user_photo');

        photo.src = smallImage;
        //photo.width = '70';
        //photo.height = 'auto';
        photo.isImageUploaded = true;
        photo.bigImage = bigImage;
        photo.smallImage = smallImage;

        $('ad-info-small-photo').value = smallImage;
        $('ad-info-large-photo').value = bigImage;
    }

    saveUserPhoto(smallImage);

    $('profile-download-photo-loader').style.display = 'none';

    closeProfilePhotoUploadDialog();
}

function Profile_RemovePhoto() {
    var photo = $('profile-photo');
    if (photo != null) {
        var photo = document.getElementById('current_user_photo');
        photo.src = window.imagesDomain + '/img/no_image_profile2.jpg';
        photo.isImageUploaded = false;
        photo.bigImage = '';
        photo.smallImage = '';

        $('ad-info-small-photo').value = '';
        $('ad-info-large-photo').value = '';
        closeProfilePhotoUploadDialog();
    }
    saveUserPhoto('')
}

function closeProfilePhotoUploadDialog() {
    if (jQ('#profile-photo-upload').is(":visible")) {
        var popup = document.getElementById('profile-photo-upload');
        var overlay = document.getElementById('popup_overlay');
        if (popup)
            popup.style.display = 'none';
        if (overlay)
            overlay.style.display = 'none';

        if (window.isAnswerPopupOpen) { //Questions & Answers page
            ShowPopup('add_answer_popup');
        }
    }

}

function Profile_DownloadPhoto() {
    var url = $('profile-download-photo-url').value;
    if (url != null && url.length) {
        //send AJAX request
        $('profile-download-photo-loader').style.display = '';
        var unique = Math.round(Math.random() * 1000);
        var urlReq = AJAXBaseURL + '/ajaxapi.php?profile_download_external_photo=1' + AJAXSessionParam + '&url=' + escape(url) + '&unique=' + unique;

        dhtmlCampusAveLoadScript(urlReq);
    }
}

function Campus_Profile_UploadImageUpload(file, onSuccess, onError, onProgress) {

    var xhr = new XMLHttpRequest();
    xhr.onload = xhr.onerror = function (param) {
        if (this.status != 200 && xhr.responseText.length == 0) {
            onError(this);
            return;
        }
        onSuccess(xhr.responseText);
    };


    xhr.upload.onprogress = function (event) {
        onProgress(event.loaded, event.total);
    }

    var oMyForm = new FormData();
    var index = document.getElementById('profile-photo-upload').imageIndex;
    oMyForm.append("campus-image-index", index);


    oMyForm.append("Filedata", file);


    xhr.open("POST", "upload-image.php");
    xhr.send(oMyForm);


}

// HTML5 upload image start
function Campus_Profile_UploadImageHTML5Error() {
    alert('Error upload file');
}
function Campus_Profile_UploadImageHTML5Success(response) {
    var response = JSON.parse(response);
    if (response.error == true) {
        document.getElementById('profile-photo-upload-error').style.color = 'red';
        CMPS_blink(document.getElementById('profile-photo-upload-error'));
        jQ('#profile-download-photo-loader').css('display', 'none');
    }
    else {
        var ar = response.res.split('\|');
        var bigImage = ar[0];
        var smallImage = ar[1];
        Profile_AddPhotoCallback(smallImage, bigImage);
    }

}
function Campus_Profile_UploadImageHTML5Progress() {
    //preloader here
    jQ('#profile-download-photo-loader').css("display", "block");
}

function Campus_Profile_UploadImageHTML5(fileInput) {
    jQ(window.currentProfilePhoto).attr('src', '/img/loading.gif');
    document.getElementById('profile-photo-upload-error').style.color = '#333333';
    var file = fileInput.files[0];
    if (file)
        Campus_Profile_UploadImageUpload(file, Campus_Profile_UploadImageHTML5Success, Campus_Profile_UploadImageHTML5Error, Campus_Profile_UploadImageHTML5Progress);
}

function saveUserPhoto(linkPhoto) {
    if (linkPhoto.indexOf('no_image_profile2.jpg') == -1) {

        jQ.ajax({
            url: "profile.htm",
            data: "action=save_user_photo&photoURL=" + linkPhoto,
            type: "POST",
            dataType: "html",
            success: function (date) {
                if (date == '1' && jQ('#complete_photo').attr('class') != 'complete') {
                    jQ('#complete_photo').attr('class', 'complete');
                    jQ('#complete_photo').html('<a>Photo Added</a>');
                }
                else if (jQ('#complete_photo').attr('class') == 'complete') {
                    jQ('#complete_photo').attr('class', '');
                    jQ('#complete_photo').html('<a href="#" onclick="return startEditPhoto();">Add Photo</a>');
                }
                else {
                    jQ('#complete_photo').html('<a href="#" onclick="return startEditPhoto();">Add Photo</a>');
                }
            }

        });
    }
}



function AddQuestionFormFocusOut() {
    var form = jQ('.ask_question_form');

    if (jQ(form).hasClass('active')) {
        return false;
    }

    jQ('.ask_question_footer', form).stop().animate({
        height: 0
    }, 200);

    jQ('.ask_question_header', form).css('border-bottom', 'none').stop().animate({
        height: 0
    }, 200);

    jQ('textarea', form).stop().animate({
        height: 85
    }, 200);
}

function AddQuestionFormFocused() {
    var form = jQ('.ask_question_form');

    if (jQ(form).hasClass('active')) {
        return false;
    }

    jQ('.ask_question_footer', form).stop().animate({
        height: 164
    }, 200);

    jQ('.ask_question_header', form).css('border-bottom', '1px solid #a6a6a6').stop().animate({
        height: 65
    }, 200);

    jQ('textarea', form).stop().animate({
        height: 114
    }, 200);

}

function AddQuestion(this_elem) {

    var form = jQ(this_elem).parents('form');
    jQ(form).append('<input type="hidden" name="verification" value="1">');

    var message = jQ('.message', form).val();
    if (message.length == 0) {
        jQ('#ask_question_error', form).text('Please enter your question');
        CMPS_blink(jQ('#ask_question_error', form).get(0), 3);
        return false;
    } else if (message.length >= 301) {
        jQ('#ask_question_error', form).text('Please rephrase the question, easily understood question with fewer than 300 characters.');
        CMPS_blink(jQ('#ask_question_error', form).get(0), 3);
        return false;
    }

    if (jQ('#question_category', form).val() == -1) {
        jQ('#ask_question_error', form).text('Please select category');
        CMPS_blink(jQ('#ask_question_error', form).get(0), 3);
        return false;
    }

    var button_onclick = jQ('#ask_question_btn', form).attr('onclick');
    jQ('#ask_question_btn', form).attr('onclick', 'return false');

    jQ('#ask_question_error', form).text('').css('visibility', 'hidden');
    jQ('.loader', form).css('display', 'inline');

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: jQ(form).serialize(),
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {

            jQ('.loader', form).css('display', 'none');

            if (!data)
                return;

            var data = JSON.parse(data);

            if (data.success) {
                jQ('.message', form).val('');
                jQ('#question_category', form).val(-1);

                if (typeof(data.message) != 'undefined') {
                    jQ('#send_confirm_msg_info div', form).html('<h2>' + data.message + '</h2>');
                    ShowPopup('send_confirm_msg_info');
                } else {
                    closePopup('default_popup');

                    if (data.redirect == window.location) {
                        window.location.reload();
                    } else {
                        window.location = data.redirect;
                    }
                }


            }
            else {
                jQ('#ask_question_error', form).text(data.message);
                CMPS_blink(jQ('#ask_question_error', form).get(0), 3);
            }

            jQ('#ask_question_btn', form).attr('onclick', button_onclick);
        }
    });

    return false;
}


function openQuestionPopup() {
    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'action=get_add_question_form',
        type: "POST",
        dataType: "html",
        success: function (data) {
            jQ('#default_popup .content-inner').html(data);

            ShowPopup('default_popup');
        }
    });

    return false;
}


function openAnswerPopup(q_id) {

    var q_id = q_id || 0;

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'action=get_question&q_id=' + q_id,
        type: "POST",
        dataType: "json",
        success: function (data) {

            if (!data.question)
                return;

            if (jQ('.questions_and_answers_detail_view').length) {
                var user_name = jQ('.top_reply_form .user_name').text();
                var user_photo = jQ('.top_reply_form .current_user_photo').attr('src');
            }
            else if (typeof loggedUserName !== 'undefined' && typeof loggedUserPhoto !== 'undefined') {
                var user_name = loggedUserName;
                var user_photo = loggedUserPhoto;
            }
            else {
                var user_name = jQ('#ask_question .user_name').text();
                var user_photo = jQ('#ask_question .current_user_photo').attr('src');
            }

            jQ('#add_answer_popup .question').html(data.question);

            jQ('#add_answer .user_name').text(user_name);
            jQ('#add_answer .current_user_photo').attr('src', user_photo);
            jQ('#add_answer input[name="question_id"]').val(q_id);

            if(user_photo && user_photo != '/img/no-user-img.jpg'){
                jQ("#add_answer .add_profile_image").hide();
            } else {
                jQ("#add_answer .add_profile_image").show();
            }

            jQ("#add_answer textarea").sceditor("instance").val('');
            jQ("#add_answer .loader").css("display", "none");
            jQ("#add_answer #add_answer_error").css("visibility", "hidden").text('');

            ShowPopup('add_answer_popup');

            jQ("#add_answer_popup textarea").sceditor('instance').getBody().keyup();

        }
    });

}

function AddAnswer(this_elem) {

    var is_popup = false;

    if (jQ(this_elem).parents('.super_popup').length) {
        is_popup = true;
    }

    var form = jQ(this_elem).parents(".add_answer_form");
    var sceditor_instance = form.find('textarea.message').sceditor("instance");

    var message = sceditor_instance.getWysiwygEditorValue();

    if (message == '' || message == '<p><br></p>' || message.indexOf(sceditor_instance.opts.placeholder + '</span></p>') != -1) {
        form.find('#add_answer_error').text('Please enter your answer');
        CMPS_blink(form.find('#add_answer_error').get(0), 3);
        return false;
    }

    form.find('.message').val(message);

    form.find('#add_answer_error').text('').css('visibility', 'hidden');
    form.find('.loader').css('display', 'inline');

    var button_action = jQ('#ask_answer_btn', form).attr('onclick');
    jQ('#ask_answer_btn', form).attr('onclick', 'return false;');

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: form.serialize(),
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {

            form.find('.loader').css('display', 'none');

            if (!data)
                return;

            var data = JSON.parse(data);

            if (data.success) {
                sceditor_instance.setWysiwygEditorValue("<p></p>");
                sceditor_instance.getBody().keyup();
                form.find('#add_answer_error .message').val('');

                if (data.redirect) {
                    UpdatePageContentPath(data.redirect, false, 'MarkAnswerVoteButtonsOnPageLoad');


                    // if(data.redirect == window.location) {
                    setTimeout(function () {
                        var qa_action_success = getCookie('qa-action-success');
                        if (typeof(qa_action_success) != 'undefined') {
                            qa_action_success = JSON.parse(qa_action_success);

                            jQ('#qa-action-success h2').html(decodeURIComponent(qa_action_success.title.replace(/\+/g, ' ')));
                            jQ('#qa-action-success .share-bt').attr('href', decodeURIComponent(qa_action_success.url))
                                .attr('data', decodeURIComponent(qa_action_success.data.replace(/\+/g, ' ')))
                                .attr('data-id', qa_action_success.id);

                            ShowPopup('qa-action-success');
                        }
                        setCookie('qa-action-success', '', {expires: -1, path: '/', domain: COOKIE_DOMAIN});
                    }, 800);
                    // }
                }
            }
            else {
                form.find('#add_answer_error').text(data.message);
                CMPS_blink(form.find('#add_answer_error').get(0), 3);
            }

            jQ('#ask_answer_btn', form).attr('onclick', button_action);
        }
    });


    return false;
}

function DeleteAnswer(elem) {

    var a_id = jQ(elem).parents('.post.answer').attr('main_id');
    var message = '<h2>Delete Answer</h2>' +
        '<span>Are you sure you want to delete this answer?</span>';

    ConfirmDeletePopup(message, function () {
        window.CodeAdapter.ajaxRequest({
            url: "ajaxapi.php",
            data: 'action=delete_answer&verification=1&a_id=' + a_id,
            type: "POST",
            dataType: "json",
            success: function (data) {

                if (!data)
                    return;

                if (data.success) {
                    jQ(elem).parents('.post.answer').slideUp("normal", function () {
                        jQ(this).remove();
                    });

                    UpdatePageContentPath(window.location.href, false, 'MarkAnswerVoteButtonsOnPageLoad');
                }

            }
        });
    });

}

function UpdatePageContentPath(page_url, progress_bar, callback_function) {
    var page_url = page_url || '';
    var progress_bar = progress_bar || false;

    if (!page_url.length) {
        window.location.reload();
        return;
    }

    if (page_url != window.location) {
        window.location = page_url;
        return;
    }

    if (progress_bar) {
        // jQ("#page-progress", window.MainWindowDocument).width((3 + Math.random() * 30) + "%").fadeIn(100);
        jQ('.content-container').html(getListingtimeline() + getListingtimeline() + getListingtimeline() + getListingtimeline() + getListingtimeline());
    }

    window.CodeAdapter.ajaxRequest({
        url: window.location.href,
        data: "ajax_content_request=1&data-type=json",
        dataType: "json",
        type: "POST",
        cache: "false",
        success: function (data) {
            // if (progress_bar) {
            //     jQ("#page-progress", window.MainWindowDocument).width("100%").delay(100).fadeOut(300);
            // }

            if (typeof(data) != 'undefined') {
                if (typeof(data.content) != 'undefined') {
                    if (!data.content.length && typeof(window.QueryString) == 'object' && typeof(window.QueryString['page']) == 'string' && ~~window.QueryString['page'] > 1) {
                        var prev_page = window.QueryString['page'] - 1;
                        if (prev_page == 1) {
                            var new_url = page_url.replace(new RegExp('[?&]page=[^&#]*(#.*)?$'), '$1').replace(new RegExp('([?&])page=[^&]*&'), '$1');
                        } else {
                            var new_url = page_url.replace("page=" + window.QueryString['page'], (prev_page == 1 ? '' : "page=" + prev_page));
                        }
                        window.location = new_url;
                    } else if (data.content.length) {
                        jQ('.content-container').html(data.content);
                    }
                }
                if (typeof(data.pn) != 'undefined') {
                    jQ('.pages-container').html(data.pn);
                }

                if (typeof(callback_function) != 'undefined' && typeof(window[callback_function]) === 'function') {
                    window[callback_function]();
                }
            } else {
                window.location.reload();
                return;
            }
        }
    });
}


function DeleteComment(elem, acode, qcode, ccode){
    var message = '<h2>Delete Comment</h2>' +
        '<span>Are you sure you want to delete this comment?</span>';

    var container = jQ(elem).parents('.post.answer').length ? jQ(elem).parents('.post.answer') : jQ(elem).parents('.question_row');

    ConfirmDeletePopup(message, function(){
        window.CodeAdapter.ajaxRequest({
            url: "ajaxapi.php",
            data: 'action=delete_comment&verification=1&c_id=' + ccode + '&a_id=' + acode,
            type: "POST",
            dataType: "json",
            success: function(data){
                if(data.success){
                    jQ(elem).parents('.answer_comment').eq(0).slideUp("normal", function(){
                        jQ(this).remove();
                    });

                    if(data.child_comment){
                        SendToSocket('qa_comment', {
                            'callback_f': 'QAChildComment',
                            'callback_fp': [null, acode, qcode, ccode, true, 1]
                        });
                    } else {
                        SendToSocket('qa_comment', {
                            'callback_f': 'QAComment',
                            'callback_fp': [null, acode, qcode, true, 1]
                        });
                        SendToSocket('qa_comment_count', {
                            'callback_f': 'QASetCommentsCount',
                            'callback_fp': [acode, qcode, (parseInt(jQ('.comments-btn', container).attr('data')) - 1)]
                        });
                    }


                }

            }
        });
    });

}

function ConfirmDeletePopup(message, deleteCallback) {

    jQ('#confirm_popup .message').html(message);

    jQ('#confirm_popup #delete').off();

    jQ('#confirm_popup #delete').click(function () {
        deleteCallback();
        closePopup('confirm_popup');
    });

    popup({
        popup_id: 'confirm_popup'
    });

}

function DeleteQuestion(elem, q_id) {

    var q_id = q_id || 0;

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'action=delete_question&verification=1&q_id=' + q_id,
        type: "POST",
        dataType: "json",
        success: function (data) {

            if (!data)
                return;

            if (data.redirect) {
                if (data.redirect == window.location) {
                    window.location.reload();
                } else {
                    window.location = data.redirect;
                }
            }

        }
    });

    return false;

}

function OpenReplyComment(elem) {

    var comment = jQ(elem).parents('.answer_comment').eq(0);


    jQ(comment).find('.action-buttons-bar:first').hide();
    jQ(comment).find('.reply_editor:first').css('display', 'table');
    jQ(comment).find('textarea.reply_textarea:first').val('');
    jQ(comment).find('textarea.reply_textarea:first').sceditor('instance').getBody().focus();
    jQ(comment).find('textarea.reply_textarea:first').sceditor('instance').setWysiwygEditorValue("<p></p>");
    jQ(comment).find('textarea.reply_textarea:first').sceditor('instance').getBody().keyup();

}


function QAChildComment(event, acode, qcode, ccode, update_flag, page) {
    var event = event || window.event;
    if (typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }
    console.log('.comment_' + ccode);
    var container = jQ('.comment_' + ccode);
    var update_flag = update_flag || false;

    if (typeof container !== 'undefined' && container.length) {
        var comment_container = jQ('.comment_container:first', container);

        var slide_spead = 500;
        var new_page = page || 1;
        var current_page = jQ(comment_container).attr('page') || 1;

        jQ('.comment_replies_link_wrap:first', container).hide();

        if (update_flag || jQ(comment_container).hasClass('update')) {
            slide_spead = 200;
            if (!jQ(comment_container).html()) {
                jQ(comment_container).append('<div class="loader"></div>');
            } else if (new_page == current_page) {
                jQ('.page-content-1:first', container).prepend('<div class="loader top remove"></div>');
            }

            if (new_page > 1) {
                jQ('.load-more:last', comment_container).remove();
                jQ(comment_container).append('<div class="loader remove"></div>');
            }

            window.CodeAdapter.ajaxRequest({
                url: "ajaxapi.php",
                data: 'action=get_qa_comments&a_id=' + acode + '&q_id=' + qcode + '&page=' + new_page + '&c_id=' + ccode + '&cpage=' + current_page + '&update=' + (update_flag ? 1 : 0),
                type: "POST",
                dataType: "html",
                success: function (data) {

                    if (jQ('.page-content-' + new_page, comment_container).length) {
                        jQ('.page-content-' + new_page, comment_container).replaceWith(data);
                    }
                    else if (new_page > 1) {
                        jQ('.loader:last', comment_container).replaceWith(data);
                    } else {
                        jQ(comment_container).html(data);
                    }

                    jQ(comment_container).find('textarea.valid').sceditor({
                        toolbar: "",
                        style: "/css/jquery.sceditor.default.css",
                        placeholder: "Add a comment..."
                    });
                    jQ(comment_container).find('textarea.reply_textarea').sceditor({
                        toolbar: "",
                        style: "/css/jquery.sceditor.default.css",
                        placeholder: "Reply..."
                    });
                    jQ(comment_container).find('textarea.edit_comment_textarea').sceditor({
                        toolbar: "",
                        style: "/css/jquery.sceditor.default.css",
                        placeholder: ""
                    });
                    jQ(comment_container).find('textarea.valid').sceditor('instance').height(28);
                    jQ(comment_container).find('textarea.valid').sceditor('instance').css('body { padding:5px 9px;color:#666; } p{ color:#666; }');

                    jQ(comment_container).find('textarea.reply_textarea, textarea.edit_comment_textarea').each(function () {
                        jQ(this).sceditor('instance').height(28);
                        jQ(this).sceditor('instance').css('body { padding:5px 9px;color:#666; } p{ color:#666; }');

                    });


                    jQ(comment_container).removeClass('update');
                    jQ('.remove', container).remove();

                    if (current_page < new_page) {
                        jQ(comment_container).attr('page', new_page);
                    }

                    MarkCommentVoteButtonsOnPageLoad();

                    if (current_page > new_page) {
                        QAChildComment(event, acode, qcode, ccode, true, new_page + 1)
                    }
                }
            });
        }

    }
}

function strip_tags(text, allowed) {
    allowed = (((allowed || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');

    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;

    return text.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    })
}

function prepareHtmlText(text) {

    text = text.replace(/(class|id|style)\s*=\s*["'][^"']+["']/gi, "");
    text = text.replace(/(\/|<)((table|tbody|tr|td|th|ul|ol|li|div|th)[^>]*)>\n/gi, "$1$2>");
    //text =  text.replace(/(\s?\n){3,}/g, "\n\n");
    //text =  text.replace(/<br>\n/g, "\n");
    //text =  text.replace(/&nbsp;/g, " ");

    text = strip_tags(text, '<a><i><em><b><span><strong><ul><ol><li><img><table><tbody><tr><td><th><u><s><sub><sup><hr><br>');

    text = text.replace(/<img\s+([^>]*)\/?>/g, "<img $1 style='max-width:100% !important;'>");

    return text;

}

function CommentUpVote(elem, event, code, qcode, ccode) {
    var event = event || window.event;
    if (typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if (jQ(elem).hasClass('disabled')) {
        return;
    }

    CommenVoteAction('.upvote-btn2', elem, code, qcode, ccode);
}

function CommentDownVote(elem, event, code, qcode, ccode) {
    var event = event || window.event;
    if (typeof(event) !== 'undefined') {
        event.preventDefault();
        event.stopPropagation();
    }

    if (jQ(elem).hasClass('disabled')) {
        return;
    }

    CommenVoteAction('.downvote-btn', elem, code, qcode, ccode);
}

function CommenVoteAction(btn_selector, elem, code, qcode, ccode) {
    var votes_number = ~~jQ('.comment_' + ccode + ' .upvote-txt:first').attr('data');
    var action = '';

    jQ('.comment_' + ccode + ' .upvote-txt:first').html('<div class="loader"></div>');

    switch (btn_selector) {
        case '.upvote-btn2':
            if (jQ(elem).hasClass('voted')) {
                action = 'downvote';
            } else {
                action = 'upvote';
            }
            break;
        case '.downvote-btn':
            if (jQ(elem).hasClass('voted')) {
                action = 'upvote';
            } else {
                action = 'downvote';
            }
            break;
    }

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'comment_vote_action=1&action=' + action + '&a_id=' + code + '&q_id=' + qcode + '&c_id=' + ccode + '&votes=' + votes_number,
        type: "POST",
        dataType: "json",
        success: function (data) {
            if (typeof data !== 'undefined') {
                if (data.success) {
                    SendToSocket('qa_vote', {
                        'callback_f': 'CommentSetVote',
                        'callback_fp': ['comment_' + ccode, btn_selector, data.vote, data.vote_sum, data.vote_formatted]
                    });

                    CommentMarkerVoted(jQ('.comment_' + ccode), btn_selector, data.vote);
                } else {
                    CommentSetVote('comment_' + ccode, btn_selector, data.vote, data.vote_sum, data.vote_formatted);

                    CommentMarkerVoted(jQ('.comment_' + ccode), btn_selector, data.vote);
                }


            }
        }
    });
}

function CommentMarkerVoted(container, btn_selector, vote) {
    switch (btn_selector) {
        case '.upvote-btn2':
            if (jQ(btn_selector + ':first', container).hasClass('voted')) {
                jQ(btn_selector + ' .text:first', container).html('Upvote');
                jQ(btn_selector + ':first', container).removeClass('voted');

                unmarkCommentVote(jQ(container).attr("class").match(/\b(comment_(.*))\b/)[1], 'up');
            } else if (vote != 0) {
                jQ(btn_selector + ' .text:first', container).html('Upvoted');
                jQ(btn_selector + ':first', container).addClass('voted');

                markCommentVote(jQ(container).attr("class").match(/\b(comment_(.*))\b/)[1], 'up');
            }

            if (jQ('.downvote-btn:first', container).hasClass('voted')) {
                jQ('.downvote-btn .text:first', container).html('Downvote');
                jQ('.downvote-btn:first', container).removeClass('voted');
                // unmarkCommentVote(jQ(container).attr("class").match(/\b(comment_(.*))\b/)[1], 'down');
            }
            break;
        case '.downvote-btn':
            if (jQ(btn_selector + ':first', container).hasClass('voted')) {
                jQ(btn_selector + ' .text:first', container).html('Downvote');
                jQ(btn_selector + ':first', container).removeClass('voted');
                unmarkCommentVote(jQ(container).attr("class").match(/\b(comment_(.*))\b/)[1], 'down');
            } else if (vote != 0) {
                jQ(btn_selector + ' .text:first', container).html('Downvoted');
                jQ(btn_selector + ':first', container).addClass('voted');
                markCommentVote(jQ(container).attr("class").match(/\b(comment_(.*))\b/)[1], 'down');
            }

            if (jQ('.upvote-btn2:first', container).hasClass('voted')) {
                jQ('.upvote-btn2 .text:first', container).html('Upvote');
                jQ('.upvote-btn2:first', container).removeClass('voted');
                // unmarkCommentVote(jQ(container).attr("class").match(/\b(comment_(.*))\b/)[1], 'up');
            }
            break;
    }
}

function CommentSetVote(elem_id, btn_selector, vote, sum, value) {
    var elem = jQ('.' + elem_id);


    if (typeof elem !== 'undefined' && elem.length) {
        jQ(elem).find('.upvote-txt:first').attr('data', sum);


        if (sum == 0) {
            jQ('.upvote-txt:first', elem).html('');
        } else {
            jQ('.upvote-txt:first', elem).show();
            jQ('.upvote-txt:first', elem).html(value + ' upvote');

            if (value < 0) {
                jQ('.upvote-txt:first', elem).hide();
            }
        }


    }
}


function markCommentVote(container, type) {
    CrossStorage.get('comment_votes', function (data) {
        var commentVotesArray = data;
        try {
            commentVotesArray = JSON.parse(commentVotesArray);
        } catch (e) {
            commentVotesArray = [];
        }

        if (!commentVotesArray) {
            commentVotesArray = [];
        }

        if (!isCommentInStorage(commentVotesArray, container, type)) {
            var commentVote = {'container': container, 'type': type};
            commentVotesArray.push(commentVote);
        }

        var unmark_type = 'down';
        if (type == 'down') {
            unmark_type = 'up';
        }

        for (var key in commentVotesArray) {
            var commentVote = commentVotesArray[key];
            if (commentVote.container == container && commentVote.type == unmark_type) {

                commentVotesArray.splice(key, 1);
            }
        }

        CrossStorage.set({
            'comment_votes': JSON.stringify(commentVotesArray)
        });

        //console.log(JSON.stringify(commentVotesArray));


    });
}


function unmarkCommentVote(container, type) {

    //console.log('Unmark');

    CrossStorage.get('comment_votes', function (data) {
        var commentVotesArray = data;
        try {
            commentVotesArray = JSON.parse(commentVotesArray);
        } catch (e) {
            commentVotesArray = [];
        }
        if (!commentVotesArray) {
            commentVotesArray = [];
        }

        for (var key in commentVotesArray) {
            var commentVote = commentVotesArray[key];
            if (commentVote.container == container && commentVote.type == type) {

                commentVotesArray.splice(key, 1);
            }
        }

        if (!commentVotesArray) {
            commentVotesArray = [];
        }

        // console.log(answerVotesArray);
        // console.log(JSON.stringify(answerVotesArray));

        CrossStorage.set({
            'comment_votes': JSON.stringify(commentVotesArray)
        });

    });
}

function isCommentInStorage(commentVotesArray, container, type) {

    if (!commentVotesArray) {
        return false;
    }

    for (var key in commentVotesArray) {
        var commentVote = commentVotesArray[key];
        if (commentVote.container == container && commentVote.type == type) {
            return true;
        }
    }
    return false;
}

function MarkCommentVoteButtonsOnPageLoad() {

    CrossStorage.get('comment_votes', function (data) {

        var commentVotesArray = data;
        try {
            commentVotesArray = JSON.parse(commentVotesArray);
        } catch (e) {
            commentVotesArray = [];
        }

        if (commentVotesArray) {
            for (var key in commentVotesArray) {
                var commentVote = commentVotesArray[key];
                if (commentVote.type == 'up') {
                    ;
                    jQ('.upvote-btn2' + ' .text:first', jQ('.' + commentVote.container)).html('Upvoted');
                    jQ('.upvote-btn2:first', jQ('.' + commentVote.container)).addClass('voted');
                } else if (commentVote.type == 'down') {
                    jQ('.downvote-btn' + ' .text:first', jQ('.' + commentVote.container)).html('Downvoted');
                    jQ('.downvote-btn:first', jQ('.' + commentVote.container)).addClass('voted');
                }
            }
        }

        jQ('.upvote-btn2:first').removeClass('disabled');
        jQ('.downvote-btn:first').removeClass('disabled');

    });


}

function showQuestionEdit() {

    jQ('.edit_question .question_title').val(jQ('h1.listing_title').text());
    jQ('.edit_question_error').css('visibility', 'hidden');
    jQ('.question_info .qa-author-menu').fadeOut(100, function () {
        jQ('.question_info .dots_link').removeClass('shown');
    });

    if (jQ('.question-desc-container').length) {
        var descr = jQ('.question-desc-container').html();
        jQ(".edit_question .question_description").sceditor('instance').setWysiwygEditorValue(descr);
        jQ('.question-desc-container').hide();
    }

    jQ('h1.listing_title').hide();
    jQ('.edit_question').slideToggle(300);
    jQ(".edit_question .question_description").sceditor('instance').getBody().keyup();
    jQ('.edit_question .question_title').focus();

    jQ("textarea.question_title").keyup();

}

function closeQuestionEdit() {
    jQ('.edit_question').slideToggle(300);
    jQ('h1.listing_title').show();
    if (jQ('.question-desc-container').length) {
        jQ('.question-desc-container').show();
    }
}

function updateQuestion() {

    var q_title = jQ('.edit_question .question_title').val();
    var q_id = jQ('.edit_question input[name="q_id"]').val();
    var sceditor_instance = jQ('.edit_question .question_description').sceditor("instance");
    var editor_value = sceditor_instance.getWysiwygEditorValue();

    if (editor_value == '' || editor_value.indexOf(sceditor_instance.opts.placeholder + '</span></p>') != -1 || strip_tags(editor_value) == '') {
        var q_descr = '';
    }
    else {
        var q_descr = editor_value;
    }

    if (q_title.length == 0) {
        jQ('.edit_question_error').text('Please enter your question');
        CMPS_blink(jQ('.edit_question_error').get(0), 3);
        return;
    }

    jQ('.edit_question_error').text('');
    jQ('.edit_question_error').css('visibility', 'hidden');

    jQ('.edit_question .loader').show();

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'action=edit_question&q_title=' + q_title + '&q_descr=' + q_descr + '&q_id=' + q_id,
        type: "POST",
        dataType: "json",
        success: function (data) {

            jQ('.edit_question .loader').hide();

            if (!data) {
                closeQuestionEdit();
                return;
            }

            if (data.success) {

                if (data.description) {
                    if (!jQ('.question-desc-container').length) {
                        jQ('<div class="question-desc-container">').insertAfter('h1.listing_title');
                    }
                    jQ('.question-desc-container').html(data.description);
                }
                else {
                    if (jQ('.question-desc-container').length) {
                        jQ('.question-desc-container').remove();
                    }
                }

                var frag = jQ(document.createDocumentFragment());
                frag.append(q_title);
                frag.append(jQ('h1.listing_title .hotlist'));
                jQ('h1.listing_title').html('');
                jQ('h1.listing_title').append(frag);

            }

            closeQuestionEdit();

        }
    });

}

function openTab(tab, link, sub_tab) {

    if (jQ('#profile_tabs > li').eq(tab - 1).hasClass('no-active')) {

        jQ('#profile_tabs > li').removeClass('active');
        jQ('#profile_tabs_content > .item').hide();

    } else {
        if (tab == 2) {
            jQ('#profile_tabs > li').get(tab - 1).click();
            if (sub_tab) {
                jQ('#qa_tabs > li').get(sub_tab - 1).click();
            }
        } else {
            jQ('#profile_tabs > li').get(tab - 1).click();
        }
    }

    if (link) {
        window.location.href = link;
    }

}

function openCommentEditor(elem) {
    var comment_text = jQ(elem).parent().find('.listing_short_description').first().html();
    var sceditor_instance = jQ(elem).parent().find('.edit_comment_textarea').first().sceditor('instance');

    sceditor_instance.setWysiwygEditorValue(comment_text);

    jQ(elem).parent().find('.edit_comment_editor .loader').first().hide();
    jQ(elem).parent().find('.edit_comment_editor .comment-btn').first().show();

    jQ(elem).parent().find('.edit_comment_editor').first().css('display', 'table');
    jQ(elem).parent().find('.listing_short_description').first().hide();
    jQ(elem).parent().find('.edit_comment_link').first().hide();
    sceditor_instance.getBody().keyup();
}

function EditComment(elem) {
    var comment_block = jQ(elem).parents('.answer_comment').first();
    var c_id = jQ(comment_block).attr('main_id');
    var sceditor_instance = jQ(comment_block).find('.edit_comment_textarea').first().sceditor('instance');

    var comment = sceditor_instance.getWysiwygEditorValue();

    if (strip_tags(comment) == '') {
        comment = '';
    }

    if (comment.length == 0) {
        comment = jQ(comment_block).find('.listing_short_description').first().html();
    }

    jQ(comment_block).find('.edit_comment_editor .loader').first().show();
    jQ(elem).hide();

    window.CodeAdapter.ajaxRequest({
        url: "ajaxapi.php",
        data: 'action=edit_comment&comment=' + encodeURIComponent(comment) + '&c_id=' + c_id,
        type: "POST",
        dataType: "json",
        success: function (data) {

            if (data && data.success) {
                comment = data.comment;
            }

            jQ(comment_block).find('.edit_comment_editor').first().hide();
            jQ(comment_block).find('.listing_short_description').first().html(comment).show();
            jQ(comment_block).find('.edit_comment_link').first().show();

        }
    });

}

function showAnswerEdit(elem) {
    var answer_block = jQ(elem).parents('.answer').first();

    jQ(".message", answer_block).sceditor({
        toolbar: "bold,italic,underline,orderedlist,bulletlist,image,link",
        style: "/css/jquery.sceditor.default.css",
        placeholder: ""
    });

    if (jQ(answer_block).find('.edit_answer_form').css('display') == 'block') {
        return;
    }

    jQ(answer_block).find('.edit_answer_error').css('visibility', 'hidden');
    jQ(answer_block).find('.loader').hide();

    jQ(answer_block).find('.qa-author-menu').fadeOut(100, function () {
        jQ(answer_block).find('.dots_link').removeClass('shown');
    });

    var answer = jQ(answer_block).find('.listing_short_description').first().html();

    var sceditor_instance = jQ(answer_block).find(".edit_answer_form .message").sceditor('instance');
    sceditor_instance.setWysiwygEditorValue(answer);

    jQ(answer_block).find('.listing_short_description').hide();

    jQ(answer_block).find('.edit_answer_form').slideToggle(300);
    sceditor_instance.getBody().keyup();

}

function editAnswer(elem) {
    var answer_block = jQ(elem).parents('.answer').first();
    var form = jQ(elem).parents(".edit_answer_form");
    var sceditor_instance = form.find('textarea.message').sceditor("instance");

    var message = sceditor_instance.getWysiwygEditorValue();

    if (message == '' || message == '<p><br></p>' || message.indexOf(sceditor_instance.opts.placeholder + '</span></p>') != -1) {
        form.find('.edit_answer_error').text('Please enter your answer');
        CMPS_blink(form.find('.edit_answer_error').get(0), 3);
        return false;
    }

    form.find('.message').val(message);

    form.find('.edit_answer_error').text('').css('visibility', 'hidden');
    form.find('.loader').css('display', 'inline');

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: form.serialize(),
        dataType: "html",
        type: "POST",
        cache: "false",
        success: function (data) {

            form.find('.loader').css('display', 'none');

            if (!data)
                return;

            var data = JSON.parse(data);

            if (data.success) {
                jQ(answer_block).find('.listing_short_description').html(data.answer);
                jQ(form).slideToggle(300, function () {
                    jQ(answer_block).find('.listing_short_description').show();
                });

            }
            else {
                form.find('.edit_answer_error').text(data.message);
                CMPS_blink(form.find('.edit_answer_error').get(0), 3);
            }

        }
    });

    return false;
}

function clearSearchResults() {
    jQ('.clear_search_results').hide();
    jQ("#search_form input[name='s']").val('').keyup().blur();
}

function profileTabClick() {

    var items = ['questions', 'listings', 'answers', 'articles'];
    var current_tab = jQ(this).text().trim().split(' ')[0].toLowerCase();

    if (current_tab == 'q&a') {
        current_tab = 'questions';
    }

    if (window.history.pushState && 'state' in window.history && history.state !== undefined) {
        var url = window.location.href;
        var url_parts = url.split('/');
        var last_part = url_parts[url_parts.length - 1];
        url_parts[url_parts.length - 1] = current_tab;

        if (items.includes(last_part) && !jQ(this).hasClass('no-active')) {
            url = url_parts.join('/');
            history.pushState('', 'profile', url);
        }
    }
}

function shareQuestion(type, event)
{
    event = event || window.event;

    var question = jQ('h1.listing_title').text().trim().replace('"', "'");

    jQ(event.currentTarget).attr('data', question);

    if (type == 'facebook') {
        shareToFacebook(event);
    } else if (type == 'twitter') {
        shareToTweet(event);
    } else if (type == 'link copy') {
        event.preventDefault();

        var link = document.getElementById('question_copy_link');

        var range = document.createRange();
        range.setStart(link.firstChild, 0);
        range.setEnd(link.firstChild, link.innerText.length);

        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);

        try {
            var successful = document.execCommand('copy');

            if (successful) {
                jQ(event.currentTarget).parent().css('position','relative');
                jQ('#question_copy_link_tooltip').show();

                jQ(event.currentTarget).parent().off();
                jQ(event.currentTarget).parent().mouseleave(function(){
                    jQ('#question_copy_link_tooltip').hide();
                });
            }
        } catch(err) {
            console.log('Unable to copy');
        }

        window.getSelection().removeAllRanges();

    }


}


function isUserLogged(){

    return getCookie('login_status') !== undefined && getCookie('login_status') && getCookie('login_status') == 1;
}


function inviteFriendsToInterest(){

    if(!isUserLogged()){
        login_required();
        return false;
    }

    if(jQ('.interest-invite').hasClass('active')){
        console.log('is active');
        return false;
    }

    var link = document.getElementById('copy-input');

    var range = document.createRange();
    range.setStart(link.firstChild, 0);
    range.setEnd(link.firstChild, link.innerText.length);

    var selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
        var successful = document.execCommand('copy');

        if (successful) {
            jQ('.interest-invite').toggleClass('active');

            setTimeout(function () {
                jQ('.interest-invite').toggleClass('active');
            }, 3000);
        }
    } catch(err) {
        console.log('Unable to copy');
    }

    window.getSelection().removeAllRanges();
}

function shareAnswer(type, event)
{
    event = event || window.event;

    if (jQ(event.currentTarget).parents('.post.pch_12').find('.listing_short_description a').length) {
        var div = jQ('<div/>').html(jQ(event.currentTarget).parents('.post.pch_12').find('.listing_short_description').html());
        div.find('a').remove();
        var answer = div.text();
    } else {
        var answer = jQ(event.currentTarget).parents('.post.pch_12').find('.listing_short_description').text().trim().replace('"', "'");
    }

    jQ(event.currentTarget).attr('data', answer);

    if (type == 'facebook') {
        shareToFacebook(event);
    } else if (type == 'twitter') {
        shareToTweet(event);
    }

}

function shareQuestion2(type, event)
{
    event = event || window.event;

    var question = jQ(event.currentTarget).parents('.post.pch_12').find('.answer-question-link h2').text().trim().replace('"', "'");

    jQ(event.currentTarget).attr('data', question);

    if (type == 'facebook') {
        shareToFacebook(event);
    } else if (type == 'twitter') {
        shareToTweet(event);
    }

}

function loadCommentsCount()
{
    var token = '786879531360371|52d6b7da38e2232d94e33d02af5caef4';

    jQ('.comments_count').each(function() {
        var elem = this;
        var url = jQ(elem).attr('data-url');

        window.CodeAdapter.ajaxRequest({
            url: "https://graph.facebook.com/v2.7/",
            data: 'id=' + encodeURIComponent(url) + '&access_token=' + token,
            type: "GET",
            async: true,
            dataType: "jsonp",
            success: function (data) {

                if (data) {
                    jQ(elem).text(data.share.comment_count);
                } else {
                    jQ(elem).text('0');
                }

            }
        });
    });
}

function sorting(that) {
    var that = jQ(that);
    var table = that.parents('table');

    if (that.data('field') == 'comments' && jQ(table).find('.comments_count img').length) {
        jQ('#admin_empty_block div').html('<h2>Comments have not been uploaded yet!</h2>');
        ShowPopup('admin_empty_block');
        return;
    }

    if(that.hasClass('sort-asc')) {
        that.removeClass('sort-asc').addClass('sort-desc');
        that.data('order','desc');
    } else if(that.hasClass('sort-desc')) {
        that.removeClass('sort-desc').addClass('sort-asc');
        that.data('order','asc');
    } else {
        that.addClass('sort-desc');
        that.data('order','desc');
    }

    jQ(table).find('.sort-asc, .sort-desc').not(that).removeClass('sort-asc').removeClass('sort-desc');

    updateReportTable();
}

function updateReportTable(pageNav)
{
    if (jQ('#shares_tab').length) {
        updateQASharesReportTable();
        return false;
    }


    showPreloader();

    if(!pageNav){
        var pageNav = jQ('ul.pages li.active:first').exists()? 'page='+jQ('ul.pages li.active:first').text():"";
    }

    if (typeof(pageNav) === "undefined")
    {
        pageNav='';
    }
    else
    {
        pageNav = '&'+pageNav;
    }

    var dataForm = '';

    dataForm += 'dateStart=' + jQ('#date_start').val();
    dataForm += '&dateEnd=' + jQ('#date_end').val();

    if(jQ(".filter_inner").exists())
    {
        dataForm = '&'+jQ(".filter_inner").serialize();
    }

    var sortingBy = jQ('.sort.sort-asc, .sort.sort-desc');

    var sortBy = '';

    if(sortingBy) {
        jQ.each(sortingBy, function(key,el){
            var el = jQ(el);
            sortBy +='&sort_by[' + el.data('field')  + ']=' + el.data('order');
        });
    }

    var comments_count = '';
    jQ('.comments_count').each(function(key,el){
        var el = jQ(el);
        comments_count += '&comments_count[' + el.data('id') + ']=' + el.text();
    });

    window.CodeAdapter.ajaxRequest({
        url: window.location.href,
        data: dataForm + sortBy + pageNav + comments_count,
        type: "POST",
        dateType: "html",
        success: function(content)
        {
            jQ("#wrap_table").html(content);
            hideAdminPopup();
        }
    });
    return false;
}

function updateQASharesReportTable(pageNav)
{
    showPreloader();

    var current_tab = null;

    jQ('#shares_tab_content .item').each(function(){
        if(jQ(this).css('display') == 'block') {
            current_tab = jQ(this);
            return false;
        }
    });

    if(!pageNav){
        var pageNav = jQ(current_tab).find('ul.pages li.active:first').exists()? 'page='+jQ(current_tab).find('ul.pages li.active:first').text():"";
    }

    if (typeof(pageNav) === "undefined")
    {
        pageNav='';
    }
    else
    {
        pageNav = '&'+pageNav;
    }

    var dataForm = '';

    if(jQ(current_tab).find(".qa_shares_filter_inner").exists())
    {
        dataForm = jQ(current_tab).find(".qa_shares_filter_inner").serialize();
    }

    var sortingBy = jQ(current_tab).find('.sort.sort-asc, .sort.sort-desc');

    var sortBy = '';

    if(sortingBy) {
        jQ.each(sortingBy, function(key,el){
            var el = jQ(el);
            sortBy +='&sort_by[' + el.data('field')  + ']=' + el.data('order');
        });
    }

    window.CodeAdapter.ajaxRequest({
        url: window.location.href,
        data: dataForm + sortBy + pageNav,
        type: "POST",
        dateType: "html",
        success: function(content)
        {
            jQ(current_tab).find(".wrap_table").html(content);

            jQ("ul.pages a").click(function(){
                var link = jQ(this).attr("href").split("?");
                updateQASharesReportTable(link[1]);
                return false;
            });

            hideAdminPopup();
        }
    });
    return false;
}

function downloadArticlesXLS(link)
{

    if (jQ('.result-table .comments_count img').length) {
        jQ('#admin_empty_block div').html('<h2>Comments have not been uploaded yet!</h2>');
        ShowPopup('admin_empty_block');
        return false;
    }

    var comments_count = '';
    jQ('.result-table .comments_count').each(function(){
        var count = jQ(this).text();

        if (count != '0') {
            comments_count += '&c['+jQ(this).data('id')+']='+count;
        }
    });

    var href = jQ(link).attr('href');
    if (comments_count) {
        href += comments_count;
    }

    window.location.href = href;
}

function addErrorIMGEvent(){
    // jQuery('.listing-list .image-column img, .listing-list .listing-list-gallery img').error(function () {
    jQuery('.listing-list .image-column img, .listing-list .listing-list-gallery img').on("error", function () {
        if (jQuery(this).attr('letter')) {
            jQuery(this).attr('src',  'https://d2hu3w5e3twj7v.cloudfront.net/letters/' + jQuery(this).attr('letter') + '-126x101.png');

        } else if (jQuery(this).attr('letter-image')) {
            jQuery(this).attr('src', jQuery(this).attr('letter-image'));
        } else if (jQ(this).closest('.listing-list-gallery').length && jQuery(this).closest('.listing-list-gallery').find('*[letter-image]')) {
            var letter_image = jQuery(this).closest('.listing-list-gallery').find('*[letter-image]').first().attr('letter-image');
            jQuery(this).attr('src', letter_image);
        } else {
            jQuery(this).attr('src', window.imagesDomain + '/img/no-image-small6-no-border.jpg');
        }

        if (jQuery(this).css("visibility") == "hidden" && (
            jQuery(this).parent().hasClass('slider_img_block') ||
            jQuery(this).parent().hasClass('static-image')
        )
        ) {
            jQuery(this).parent().css({
                'background-image': 'url(' + jQuery(this).attr('src') + ')',
                'background-size': 'initial',
                '-webkit-background-size': 'initial',
            });
        }
    });
}


function checkFlexWrapSupport(){
    var d = document.documentElement.style
    if (!('flexWrap' in d) && !('WebkitFlexWrap' in d) && !('msFlexWrap' in d)){
        jQ('.flex').addClass('flex-no-supported');
    }
}

function openMenuBox(elem){
    var menu_box = jQ(elem).next('.menu-box');

    jQ(menu_box).fadeIn('fast', function(){
        jQ(document.body).on("click", openMenuBoxCloseHandler);
    });
}

function openMenuBoxCloseHandler(e){
    if (jQ('.menu-box:visible').length && !jQ(e.target).parents('.menu-box').length) {
        jQ('.menu-box').fadeOut('fast');

        jQ(document.body).off("click", openMenuBoxCloseHandler)
    }
}


/*
 *
 * Comment message JS START
 *
 */

// HTML5 upload image end
function Comments_InitSwfUpload()
{
    jQ('#campus-image-upload input.grey_submit').hide();

    if(Campus_IsHTML5UploadAllowed())
    {
        var html = '<form name="fileupload" id="fileupload" class="btn btn-blue"><span>Select and Upload</span><input type="file" name="Filedata"  accept="image/*;capture=camera" onchange="Comments_UploadImageHTML5(this);"></form>';
        $('campus-posting-upload-image-button').innerHTML = html;

    } else if(window.CommentsUploadSWF==null) {
        var settings = {
            flash_url : CampusPostingRootURL+"js/swfupload.swf",
            upload_url: CampusPostingRootURL+"upload-image.php",
            file_size_limit : "2000 KB",
            file_types : "*.jpg;*.jpeg;*.gif;*.png",
            file_types_description : "Images",
            file_upload_limit : 0,
            file_queue_limit : 1,
            custom_settings : {
                progressTarget : "campus-posting-upload-image-progress-bar"
            },

            prevent_swf_caching:true,
            debug: false,

            // Button settings
            button_image_url: window.imagesDomain+"/img/button/continue_button3.png",
            button_width: "153",
            button_height: "38",
            button_placeholder_id: "campus-posting-upload-image-button",
            button_text: '<font size="16" color="#FFFFFF">Select and Upload</font>',
            button_text_style: '',
            button_text_left_padding: 9,
            button_text_top_padding: 8,

            file_dialog_complete_handler: Campus_fileDialogComplete,

            upload_success_handler: Comments_UploadImageSuccess,
            upload_progress_handler: Campus_UploadProgress,
            upload_complete_handler: Campus_UploadComplete,
            upload_error_handler: Campus_UploadImageError,
            file_queue_error_handler: Campus_UploadImageQueueError
        };

        window.CommentsUploadSWF = new SWFUpload(settings);

    }

    if(document.getElementById("campus-posting-upload-image-progress-bar"))
        document.getElementById("campus-posting-upload-image-progress-bar").style.width = '1px';

}
function Comments_UploadImageSuccess(file, server_data) {
    var imageUpload = document.getElementById('campus-image-upload');
    //hide image download preloader
    jQ('#campus-posting-download-image-loader').css('display', 'none');
    var server_data = JSON.parse(server_data);
    var ar = server_data.res.split('\|');
    var bigImage = ar[0];
    var smallImage = ar[1];

    Comments_DownloadPhotoCallback(1, imageUpload.imageIndex, smallImage, bigImage);
}

function Comments_AddPhoto(index)
{
    // document.getElementById('error_upload_posting_img').innerHTML = '';
    var imageUpload = document.getElementById('campus-image-upload');
    imageUpload.imageIndex = index;
    Comments_InitSwfUpload();

    jQ("#campus-image-upload .column:last form").attr("onsubmit", "Comments_DownloadPhoto(document.getElementById('campus-image-upload').imageIndex);return false;");
    jQ("#campus-image-upload .column:last .btn-blue").attr("onclick", "Comments_DownloadPhoto(document.getElementById('campus-image-upload').imageIndex);return false;");

    popup({
        popup_id:"campus-image-upload"
    });
}

function Comments_UploadImageHTML5(fileInput) {
    var file = fileInput.files[0];
    if (file)
        Campus_UploadImageUpload(file, Comments_UploadImageHTML5Success, Campus_UploadImageHTML5Error, Campus_UploadImageHTML5Progress);
}

function Comments_UploadImageHTML5Success(response) {
    var imageUpload = document.getElementById('campus-image-upload');

    var response = JSON.parse(response);
    if (response.error == true) {
        Campus_UploadImageError();
    }
    else {
        var ar = response.res.split('\|');
        var bigImage = ar[0];
        var smallImage = ar[1];

        Comments_DownloadPhotoCallback(1, imageUpload.imageIndex, smallImage, bigImage);
    }

}

function Comments_DownloadPhoto(index)
{
    var url = $('campus-posting-download-image-url').value;
    if(url!=null && url.length)
    {
        //send AJAX request
        $('campus-posting-download-image-loader').style.display = 'block';
        var unique = Math.round(Math.random()*1000);
        var urlReq = AJAXBaseURL+'/ajaxapi.php?callback=Comments_DownloadPhotoCallback&download_external_image=1'+AJAXSessionParam+'&url='+escape(url)+'&index='+index+'&unique='+unique;
        dhtmlCampusAveLoadScript(urlReq);
    }
}

function Comments_DownloadPhotoCallback(status,index,smallImage,bigImage)
{

    $('campus-posting-download-image-loader').style.display = 'none';
    $('campus-posting-download-image-url').value = 'http://';
    if(status>0) {
        jQ('.comment-images-container[comment-listing-id=' + index + ']').html('\
            <span class="image-container">\
                <div class="image-wrap">\
                    <span class="delete"></span>\
                    <div class="image-cover" style="background-image: url(' + smallImage + ')"></div>\
                    <input type="hidden" name="image_small" value="' + smallImage + '"/>\
                    <input type="hidden" name="image_large" value="' + bigImage + '"/>\
                </div>\
            </span>');
    }
    CommentscloseImageUploadDialog();

}
function CommentscloseImageUploadDialog()
{
    var popup = document.getElementById('campus-image-upload');
    if(popup)
        popup.style.display='none';
    jQ('.popup_overlay, #campus-posting-download-image-loader').hide();
    jQ('#campus-image-upload-error').text('Note: must be in .png, .gif or .jpg format').css('color','');
}

if (typeof Campus_Posting_UploadImageError === 'undefined') {
    function Campus_Posting_UploadImageError(err) {
        if (typeof(err) === 'undefined') {
            var message = 'All images must be in .png, .gif or .jpg format, contact <a href="mailto:' + EmailAddr + '" target="_blank">' + EmailAddr + '</a> if you need any help';
        } else {
            var message = err;
        }

        jQ('#campus-posting-download-image-loader').css('display', 'none');
        CMPS_blink(CMPS_setHTML(document.getElementById('error_upload_posting_img'), message));

    }
}

function scrollToReplyForm(form_id) {
    openReplyForm(jQ("#" + form_id).prev(jQ('.message-reply-login')));
    jQ("#" + form_id).closest('.post-info').find('.hide-from-feed').css('display', 'block');

    jQ('html, body').animate({
        scrollTop: jQ("#" + form_id).offset().top - 500
    }, 200);
}

function openReplyForm(elem) {
    jQ(elem).hide();
    jQ(elem).next('.message-reply').show();
    jQ(elem).next('.message-reply').find('.comment-textarea').focus();
}

function SentComment(form, page) {

    var comment_text = jQ(form).find('.comment-textarea').html();
    var listing_id = jQ(form).find('input[name="listing_id"]').val();
    var page_name = jQ(form).find('input[name="page_name"]').val();
    jQ(form).find('input[name="comment"]').val(comment_text);

    jQ(form).find('.preloader').show();

    window.CodeAdapter.ajaxRequest({
        url: "index.php",
        data: jQ(form).serialize(),
        dataType: "json",
        type: "POST",
        cache: "false",
        success: function (data) {
            jQ(form).find('.preloader').hide();

            if (data.response) {
//                        jQ('#default_popup .content-inner').html('<div class="success-message">Your reply was sent</div>');
//                        ShowPopup('default_popup');
                // Clear form
                jQ(form).find('.comment-textarea').html('');
                jQ(form).find('.comment-images-container').html('');

                if (page_name == 'messages_channel_detail' || page_name == 'photos_channel_detail') {
                    // jQ('html, body').animate({
                    //     scrollTop: jQ('.answers-container').offset().top - 500
                    // }, 200);

                    jQ('.answers-container').html(data.responses_content);
                    jQ('.replies-count').html(data.responses_count);


                } else {
                    // jQ('html, body').animate({
                    //     scrollTop: jQ('.responses-block-' + listing_id).offset().top - 500
                    // }, 200);

                    if (jQ('.responses-block-' + listing_id).get(0)) {
                        jQ('.responses-block-' + listing_id).html(data.responses_content);
                        jQ('.responses-count-' + listing_id).html(data.responses_count);
                        // jQ('.responses-block-' + listing_id).closest('.post-info').find('.hide-from-feed').css('display', 'block');
                    }
                }
            } else {
                jQ('#default_popup .content-inner').html('<div class="error-message">' + data.error + '</div>');
                ShowPopup('default_popup');
            }
        }
    });
    return false;
}

// when video ends
function onFancyboxPlayerStateChange(event) {
    if(event.data === 0) {
        jQ.fancybox.close()
    }
}


// function processImagesScale()
// {
//     console.log('process image scover');
//     jQ(".single .image-cover, .single .image-contain").each(function(index, element){
//
//         if (jQ(element).hasClass('precessed')) {
//             return;
//         }
//         jQ(element).addClass('precessed');
//         var img = new Image();
//         var background_image = jQ(element).css('background-image');
//         background_image = background_image.replace('url(','').replace(')','').replace(/\"/gi, "");
//         img.src = background_image;
//
//         jQ(img).one("load", function () {
//             jQ(element).css({'opacity' : 1});
//             var width = this.width;
//             var height = this.height;
//
//             console.log(background_image);
//             console.log(width + ' x ' + height);
//
//             var container_width = element.clientWidth;
//             var container_height = element.clientHeight;
//
//             //if more then by 75% by height
//             if (jQ(element).attr('force-scale')) {
//                 jQ(element).css({'background-size': jQ(element).attr('force-scale')});
//                 // jQ(element).addClass('bordered');
//                 // jQ(element).before('<div class="smooth-background" style="background-image: url(' + background_image + ');"></div>');
//             } else /*if (width <= height && (height / container_height) > 0.75 ) {
//                 jQ(element).css({'background-size': 'contain'});
//                 // jQ(element).addClass('bordered');
//                 jQ(element).before('<div class="smooth-background" style="background-image: url(' + background_image + ');"></div>');
//             } else */if (width <= container_width && height <= container_height) {
//                 jQ(element).css({'background-size': 'auto'});
//
//                 // jQ(element).addClass('bordered');
//                 jQ(element).css({'height' : height + 'px'});
//                 jQ(element).closest('.index-post-gallery').find('.single').css({'height' : (height + 5) + 'px'});
//
//                 // jQ(element).before('<div class="smooth-background" style="background-image: url(' + background_image + ');"></div>');
//             } else if (width <= container_width && height >= container_height) {
//                 jQ(element).css({'background-size': 'contain'});
//                 // jQ(element).addClass('bordered');
//                 // jQ(element).before('<div class="smooth-background" style="background-image: url(' + background_image + ');"></div>');
//             }
//         }).each(function () {
//             if (this.complete) {
//                 jQ(this).load();
//             }
//         });
//     });
// }

function collegeMapLoadError(e){
    var target = e.target;
    target.nextElementSibling.style.display='none';
}

function triggerEvent(el, eventName, options) {
    var event;
    if (window.CustomEvent) {
        event = new CustomEvent(eventName, options);
    } else {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, true, true, options);
    }
    el.dispatchEvent(event);
}

function sendGoogleAnalytics(type, category, action, label) {
    if(typeof(ga) !== 'undefined') {
        ga('send', type, action, category, label);
    }
}

function viewOnMap (lat, lng) {
    requirejs(['popup/viewOnMap'], function (popup) {
        popup.instance.open(lat, lng);
    });
}

function AdminStatisticPageSearch() {
    console.log('test');
}

function ProcessFastSearchAutoFill(elem, form) {
    if (typeof(form.getElementsByClassName('search-autocomplete')) != 'undefined' && typeof(form.getElementsByClassName('search-autocomplete')[0]) != 'undefined') {

        form.getElementsByClassName('search-autocomplete')[0].innerHTML = '';

        window.CodeAdapter.ajaxRequest({
            url: "ajax_content.php",
            data: "action=get_search_autofill_json&s=" + jQ(elem).val(),
            type: "POST",
            dataType: "json",
            success: function (json) {
                if (
                    typeof(form.getElementsByClassName('search_input')) != 'undefined' &&
                    typeof(form.getElementsByClassName('search_input')[0]) != 'undefined' &&
                    typeof(form.getElementsByClassName('search-autocomplete')) != 'undefined' &&
                    typeof(form.getElementsByClassName('search-autocomplete')[0]) != 'undefined' &&
                    typeof(json) != 'undefined' &&
                    typeof(json.match) != 'undefined' &&
                    typeof(json.autofill) != 'undefined'
                ) {
                    if(form.getElementsByClassName('search_input')[0].value.toLowerCase() == json.match.toLowerCase()) {
                        form.getElementsByClassName('search-autocomplete')[0].innerHTML = form.getElementsByClassName('search_input')[0].value + '<span>' + json.autofill + '</span>';
                    }
                }
            }
        });
    }
}


function ProcessFastSearch(elem, form, no_result_text_flag) {
    search_str = jQ(elem).val();
    var no_result_text_flag = no_result_text_flag || false;

    if (search_str == 'Search') return;

    if (!window.results_container) {
        window.results_container = document.createElement('div');
        window.results_container.className = "fast_search_results";
        window.results_container.id = "fast_search_results";

        jQ(form).append(window.results_container);

    }

    window.results_container.style.borderStyle = 'none';

    if (typeof(page_type) !== 'undefined' && ( page_type.type == 'news_category' || page_type.type == 'news_detail' || page_type.type == 'homepage' )) {

        if (!window.news_container) {
            window.news_container = document.createElement('div');
            window.news_container.id = "fast_search_news_results";
            window.news_container.style.display = 'none';
            window.results_container.appendChild(window.news_container);
        }
        else {
            window.news_container = $('fast_search_news_results');
        }

        if (!window.classifieds_container) {
            window.classifieds_container = document.createElement('div');
            window.classifieds_container.id = "fast_search_classifieds_results";
            window.classifieds_container.style.display = 'none';
            window.results_container.appendChild(window.classifieds_container);
        }
        else {
            window.classifieds_container = $('fast_search_classifieds_results');
        }

    } else {
        if (!window.classifieds_container) {
            window.classifieds_container = document.createElement('div');
            window.classifieds_container.id = "fast_search_classifieds_results";
            window.classifieds_container.style.display = 'none';
            window.results_container.appendChild(window.classifieds_container);
        }
        else {
            window.classifieds_container = $('fast_search_classifieds_results');
        }

        if (!window.news_container) {
            window.news_container = document.createElement('div');
            window.news_container.id = "fast_search_news_results";
            window.news_container.style.display = 'none';
            window.results_container.appendChild(window.news_container);
        }
        else {
            window.news_container = $('fast_search_news_results');
        }
    }


    if (search_str.length < 2) {
        window.classifieds_container.style.display = 'none';
        window.news_container.style.display = 'none';
        return false;
    }

    jQ(window.results_container).css("display", "").click(function (event) {
        event.stopPropagation();
    });

    jQ("#search_form .overflow").click(function (event) {
        event.stopPropagation();
    });

    var plugin_selected_university = false;
    if (typeof(plugin_data) !== 'undefined' && getCookie('selected_university') !== undefined) {
        var plugin_selected_university = JSON.parse(getCookie('selected_university'));
    }

    var additional_search_params = '';
    if (typeof(window.QueryString) == 'object') {
        if (typeof(window.QueryString['geo-city']) == 'string' && typeof(window.QueryString['geo-state']) == 'string') {
            additional_search_params = '&geo-city=' + window.QueryString['geo-city'] + '&geo-state=' + window.QueryString['geo-state'];
        } else if (typeof(window.QueryString['geo-ip']) == 'string') {
            additional_search_params = '&geo-ip=' + window.QueryString['geo-ip'];
        }
    }

    window.fast_search_run = window.CodeAdapter.ajaxRequest({
        url: "ajax_content.php",
        data: "action=get_search_json&s=" + search_str + additional_search_params,
        type: "POST",
        dataType: "json",
        success: function (json) {
            while (window.classifieds_container.hasChildNodes()) {
                window.classifieds_container.removeChild(window.classifieds_container.lastChild);
            }

            if (window.CodeAdapter.project_type !== 'wordpress_plugin') {
                while (window.news_container.hasChildNodes()) {
                    window.news_container.removeChild(window.news_container.lastChild);
                }
            }

            if ((json.classifieds.items.length || json.news.items.length) && search_str.length) {
                //CLASSIFIEDS
                if (json.classifieds.items.length) {
                    var classifieds_title = document.createElement('div');
                    classifieds_title.className = "fast_search_title";
                    classifieds_title.innerHTML = "Classifieds (" + json.classifieds.total_found + ")";

                    window.classifieds_container.appendChild(classifieds_title);

                    for (key in json.classifieds.items) {

                        var item_container = document.createElement('div');
                        item_container.className = "fast_search_item";

                        var item_container_text = document.createElement('div');
                        item_container_text.className = "fast_search_item_text";

                        var item = json.classifieds.items[key];

                        if (plugin_selected_university) {
                            item.link = item.link.replace('/student/', '/' + plugin_selected_university.slug + '/');
                        }

                        var linkEl = document.createElement('a');
                        linkEl.setAttribute("href", item.link);
                        linkEl.innerHTML = item.title;
                        if (item.link.indexOf('view_external_link') != -1)
                            linkEl.setAttribute("target", "_blank");
                        linkEl.className = "fast_search_link";

                        var imgLinkEl = document.createElement('a');
                        imgLinkEl.setAttribute("href", item.link);
                        if (item.link.indexOf('view_external_link') != -1)
                            imgLinkEl.setAttribute("target", "_blank");

                        imgLinkEl.className = "fast_search_image";

                        var imgEl = document.createElement('img');
                        if (item.image) {
                            imgEl.setAttribute("src", item.image);
                            imgEl.onerror = function () {
                                this.setAttribute("src", window.imagesDomain + '/img/no-image-small.jpg');
                                this.className = "no-photo";
                            }
                        }
                        else {
                            imgEl.setAttribute("src", window.imagesDomain + '/img/no-image-small.jpg');
                            imgEl.className = "no-photo";
                        }

                        imgLinkEl.appendChild(imgEl);


                        var additional_html = document.createElement('div');
                        additional_html.innerHTML = item.additional_html;
                        additional_html.className = "fast_search_additional";

                        item_container.appendChild(imgLinkEl);

                        item_container_text.appendChild(linkEl);

                        item_container_text.appendChild(additional_html);

                        item_container.appendChild(item_container_text);

                        var clear = document.createElement('div');
                        clear.className = "clear";
                        item_container.appendChild(clear);

                        window.classifieds_container.appendChild(item_container);
                    }

                    var see_all_classifieds_container = document.createElement('div');
                    see_all_classifieds_container.className = 'fast_search_see_all';
                    var see_all_link = document.createElement('a');
                    see_all_link.innerHTML = 'See all Classifieds results';
                    if (typeof(plugin_data) !== 'undefined') {
                        if (plugin_selected_university) {
                            see_all_link.setAttribute("href", "/" + plugin_selected_university.slug + "/classifieds?s=" + search_str);
                        }
                        else {
                            see_all_link.setAttribute("href", "/student/classifieds?s=" + search_str);
                        }
                    }
                    else {
                        see_all_link.setAttribute("href", "/classifieds?s=" + search_str);
                    }
                    see_all_classifieds_container.appendChild(see_all_link);
                    window.classifieds_container.appendChild(see_all_classifieds_container);

                    if (typeof(DOMAIN_ID) != 'undefined' && DOMAIN_ID == 0) {
                        var find_your_school_container = document.createElement('div');
                        find_your_school_container.className = 'fast_search_find_school';
                        find_your_school_container.innerHTML = 'Select Your School to see Local Results';
                        find_your_school_container.onclick = function () {
                            ShowPopup('search_popup');
                        };
                        window.classifieds_container.appendChild(find_your_school_container);
                    }
                }
                //NEWS

                if (json.news.items.length) {
                    var news_title = document.createElement('div');
                    news_title.className = "fast_search_title";
                    news_title.innerHTML = "News (" + json.news.total_found + ")";

                    window.news_container.appendChild(news_title);

                    for (key in json.news.items) {

                        var item_container = document.createElement('div');
                        item_container.className = "fast_search_item";

                        var item_container_text = document.createElement('div');
                        item_container_text.className = "fast_search_item_text";

                        var item = json.news.items[key];

                        var linkEl = document.createElement('a');
                        linkEl.setAttribute("href", item.link);
                        linkEl.innerHTML = item.title;
                        linkEl.className = "fast_search_link";

                        var imgLinkEl = document.createElement('a');
                        imgLinkEl.setAttribute("href", item.link);
                        imgLinkEl.className = "fast_search_image";

                        var imgEl = document.createElement('img');
                        if (item.image) {
                            imgEl.setAttribute("src", item.image);
                        }
                        else {
                            imgEl.setAttribute("src", window.imagesDomain + '/img/no-image-small.jpg');
                            imgEl.className = "no-photo";
                        }

                        imgLinkEl.appendChild(imgEl);

                        var additional_html = document.createElement('div');
                        additional_html.innerHTML = item.additional_html;
                        additional_html.className = "fast_search_additional";

                        item_container.appendChild(imgLinkEl);

                        item_container_text.appendChild(linkEl);

                        item_container_text.appendChild(additional_html);

                        item_container.appendChild(item_container_text);

                        var clear = document.createElement('div');
                        clear.className = "clear";
                        item_container.appendChild(clear);

                        window.news_container.appendChild(item_container);
                    }

                    var see_all_news_container = document.createElement('div');
                    see_all_news_container.className = 'fast_search_see_all';
                    var see_all_link = document.createElement('a');
                    see_all_link.innerHTML = 'See all News results';
                    see_all_link.setAttribute("href", "/news?s=" + search_str);
                    see_all_news_container.appendChild(see_all_link);
                    window.news_container.appendChild(see_all_news_container);
                }

                jQ(".fast_search_overflow").replaceWith(jQ(".fast_search_overflow").contents());

                window.classifieds_container.style.display = '';
                window.news_container.style.display = '';


            }
            else if (no_result_text_flag) {
                var classifieds_title = document.createElement('div');
                classifieds_title.className = "fast_search_title no_relults";
                classifieds_title.innerHTML = "No Results";
                window.classifieds_container.appendChild(classifieds_title);

                jQ(".fast_search_overflow").replaceWith(jQ(".fast_search_overflow").contents());

                window.classifieds_container.style.display = '';
                window.news_container.style.display = '';
            }
            else if (!window.plugin_fast_search_run ) {
                window.classifieds_container.style.display = 'none';
                window.news_container.style.display = 'none';
            }

            window.results_container.style.borderStyle = '';
        }
    });

    if (window.CodeAdapter.project_type === 'wordpress_plugin') {
        window.plugin_fast_search_run  = jQ.ajax({
            url: location.origin + ( plugin_data.uloop_university_id > 0 ? '/' + plugin_data.uloop_university_slug : '' ) + "/ajax/search/" + encodeURIComponent(search_str),
            type: "GET",
            dataType: "json",
            success: function (json) {

                while (window.news_container.hasChildNodes()) {
                    window.news_container.removeChild(window.news_container.lastChild);
                }

                if (json !== null && typeof  json !== "undefined" && ~~json.total_found) {

                    var news_title = document.createElement('div');
                    news_title.className = "fast_search_title";
                    news_title.innerHTML = "News (" + json.total_found + ")";

                    window.news_container.appendChild(news_title);

                    for (key in json.items) {
                        var item_container = document.createElement('div');
                        item_container.className = "fast_search_item";

                        var item_container_text = document.createElement('div');
                        item_container_text.className = "fast_search_item_text";

                        var item = json.items[key];

                        if (typeof(plugin_data) !== 'undefined' && plugin_data.plugin_param == 'universityparent_api=true' && typeof(plugin_data.uloop_university_slug) !== 'undefined') {
                            item.link = item.link.replace('/' + plugin_data.uloop_university_slug + '/', '/');
                        }

                        var linkEl = document.createElement('a');
                        linkEl.setAttribute("href", item.link);
                        linkEl.innerHTML = item.title;
                        linkEl.className = "fast_search_link";

                        var imgLinkEl = document.createElement('a');
                        imgLinkEl.setAttribute("href", item.link);
                        imgLinkEl.className = "fast_search_image";

                        var imgEl = document.createElement('img');
                        if (item.image) {
                            imgEl.setAttribute("src", item.image);
                        }
                        else {
                            imgEl.setAttribute("src", window.imagesDomain + '/img/no-image-small.jpg');
                            imgEl.className = "no-photo";
                        }

                        imgLinkEl.appendChild(imgEl);

                        var additional_data_html = '';
                        additional_data_html += '<a href="' + item.category_link + '">' + item.category_name + '</a> ';
                        additional_data_html += item.date + ' ';
                        if (item.author_name.indexOf("admin") === -1) {
                            additional_data_html += '<a href="' + item.author_link + '">' + item.author_name + '</a> ';
                        }
                        var additional_html = document.createElement('div');
                        additional_html.innerHTML = additional_data_html;
                        additional_html.className = "fast_search_additional";

                        item_container.appendChild(imgLinkEl);

                        item_container_text.appendChild(linkEl);

                        item_container_text.appendChild(additional_html);

                        item_container.appendChild(item_container_text);

                        var clear = document.createElement('div');
                        clear.className = "clear";
                        item_container.appendChild(clear);

                        window.news_container.appendChild(item_container);
                    }

                    var see_all_news_container = document.createElement('div');
                    see_all_news_container.className = 'fast_search_see_all';
                    var see_all_link = document.createElement('a');
                    if (typeof(plugin_data) !== 'undefined' && plugin_data.plugin_param != 'universityparent_api=true') {
                        see_all_link.innerHTML = 'See all News results';
                    }

                    if (window.CodeAdapter.plugin_param === "universityparent_api=true") {
                        see_all_link.setAttribute("href", '/' + plugin_data.uloop_university_slug + "/news?s=" + search_str);
                    } else {
                        see_all_link.setAttribute("href", "/category/news?s=" + search_str);
                    }

                    see_all_news_container.appendChild(see_all_link);
                    window.news_container.appendChild(see_all_news_container);

                    window.classifieds_container.style.display = '';
                    window.news_container.style.display = '';

                    jQ(".fast_search_overflow").replaceWith(jQ(".fast_search_overflow").contents());
                }
                else if (!window.fast_search_run) {
                    window.classifieds_container.style.display = 'none';
                    window.news_container.style.display = 'none';
                }

                window.results_container.style.borderStyle = '';
            }
        });
    }
}
